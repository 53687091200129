import React, { useEffect, useState, Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { useTranslate } from "react-redux-multilingual";
import { CartContainer, UserLogin, WishList, WhatsApp } from "containers";
import { metrikaTargetClick } from "services";

const NavBar = (props) => {
  const translate = useTranslate();
  const { openMenu, toggleMenu } = props;
  const [navClose, setNavClose] = useState({});

  useEffect(() => {
    if (window.innerWidth < 750) {
      setNavClose({ left: "-410px" });
    }
    if (window.innerWidth < 1199) {
      setNavClose({ left: "-300px" });
    }
  }, []);

  useEffect(() => {
    if (openMenu) {
      setNavClose({ left: "0px" });
    } else {
      setNavClose({ left: "-410px" });
    }
  }, [openMenu]);

  const { settings = {} } = props.env.www || {};

  return (
    <div>
      <div className="main-navbar">
        <div id="mainnav">
          <ul className="nav-menu" style={navClose}>
            <li className="back-btn" onClick={toggleMenu}>
              <div className="mobile-back text-left">
                <i className="fa fa-angle-left mr-2" aria-hidden="true"></i>
                <span>{translate("back")}</span>
              </div>
            </li>
            {/* 
            <li>
              <Link
                to="/sell"
                className="nav-link"
                data-id="sdat-odejdu"
                onClick={metrikaTargetClick}
              >
                {translate("sell")}
              </Link>
            </li>
           
            {CONTEXT_SEGMENT === CONTEXT_SEGMENTS.KIDS ? null : (
              <>
                <li>
                  <Link
                    to="/female"
                    className="nav-link"
                    data-id="kupit-female"
                    onClick={metrikaTargetClick}
                  >
                    {translate(
                      `context_segment_gender_female_${CONTEXT_SEGMENT}`,
                    )}
                  </Link>
                </li>

                <li>
                  <Link
                    to="/male"
                    className="nav-link"
                    data-id="kupit-male"
                    onClick={metrikaTargetClick}
                  >
                    {translate(
                      `context_segment_gender_male_${CONTEXT_SEGMENT}`,
                    )}
                  </Link>
                </li>
              </>
            )}


            <li>
              <WhatsApp>WhatsApp</WhatsApp>
            </li>

          */}
            <li>{props.rightButton || null}</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  env: state.frontend.env,
});

export default connect(mapStateToProps, {})(NavBar);
