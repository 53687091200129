import _ from "lodash";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import AboutAstrogotchiBlock from "components/standart/about-block";

import LocalesValues from "components/standart/LocalesValues";

import { history } from "store";

import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { useTranslate } from "react-redux-multilingual";
import { customerApi } from "reducers/api";

const { session } = customerApi.actions;

const CheckAssistantBlock = (props) => {
  const translate = useTranslate();
  const { sessionStore, envStore } = props;
  const { items, currentItem } = sessionStore;

  const [openModal, setOpenModal] = useState(null);
  const cancelHandler = () => {
    props.logout(
      {},
      {
        onSuccess() {
          history.replace("/");
        },
      },
    );
  };

  /* как мне следует поступить в данной ситуации? */
  useEffect(() => {
    // console.log("current assistant", assistant);
    if (!items.length) {
      setOpenModal(true);
    } else if (!currentItem) {
      const [item] = items;
      history.push(`/go?alias=${item.category.alias}`);
    }
  }, [items]);

  return openModal ? (
    <Modal centered wrapClassName="fullscreen-modal" isOpen={openModal} size="md">
      <ModalHeader>
        {/* {translate(account.name ? "settings_info_edit" : "settings_info_edit")} */}
      </ModalHeader>
      <ModalBody>
        <div className="row">
          <div className="col-lg-3 col-sm-hidden" />
          <div className="col">
            <div className="text-center">
              <label className="large-header mb-3">{translate("item_stop")}</label>

              <div className="content-html mb-4">
                <LocalesValues values={envStore.www?.settings?.stopText} isHtml={true} />
              </div>

              <div className="form-group text-center">
                <button type="button" className="btn btn-outline" onClick={cancelHandler}>
                  {translate("close")}
                </button>
              </div>

              <div className="pb-5" />
              <AboutAstrogotchiBlock />
              <div className="pb-5" />
            </div>
          </div>
          <div className="col-lg-3 col-sm-hidden" />
        </div>
      </ModalBody>
    </Modal>
  ) : null;
};
const mapeStateToProps = (state) => ({
  sessionStore: state.customer.session,
  envStore: state.frontend.env,
});
export default connect(mapeStateToProps, {
  ...session.actions,
})(CheckAssistantBlock);
