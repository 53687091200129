import _ from "lodash";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { LoadingIcon } from "components/standart";

import { useTranslate } from "react-redux-multilingual";
import { customerApi } from "reducers/api";

const { api, session } = customerApi.actions;

const LoveBlock = (props) => {
  const translate = useTranslate();
  const { sessionStore } = props;

  const [about, setAbout] = useState(null);
  const [loading, setLoading] = useState(null);

  const [openModal, setOpenModal] = useState(null);
  const toggleModal = () => setOpenModal(!openModal);
  useEffect(() => {}, [openModal]);

  return (
    <div>
      <div
        className="px-4 pt-3 pb-4 border rounded position-relative"
        onClick={toggleModal}
        style={{ cursor: "pointer" }}
      >
        <div className="soon-corner">
          <span className="text">{translate("soon")}</span>
        </div>
        <div className="large-header mb-3">{translate("love_header")}</div>
        <div className="standart-header">{translate("love_description")}</div>
      </div>
      {openModal && (
        <Modal
          centered
          wrapClassName="fullscreen-modal"
          isOpen={openModal}
          size="md"
          toggle={toggleModal}
        >
          <ModalHeader toggle={toggleModal}></ModalHeader>
          <ModalBody>
            <div className="text-center">
              {loading ? (
                <LoadingIcon />
              ) : (
                <div>
                  <div className="large-header mb-3">{translate("under_construction")}</div>
                  <div className="standart-header">{translate("under_construction_winter")}</div>
                </div>
              )}
            </div>
          </ModalBody>
        </Modal>
      )}
    </div>
  );
};
const mapeStateToProps = (state) => ({
  systemEnv: state.customer.env,
  sessionStore: state.customer.session,
});
export default connect(mapeStateToProps, {
  ...session.actions,
  getAbout: api.get("/account/today_about"),
})(LoveBlock);
