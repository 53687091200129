import _ from "lodash";
import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { useTranslate } from "react-redux-multilingual";
import { customerApi } from "reducers/api";
import { scores } from "views/init";

const { api } = customerApi.actions;
const TodayScore = (props) => {
  const { NPS } = props;
  const [score, setScore] = useState(null);
  useEffect(() => {
    NPS && setScore(NPS.score);
  }, [NPS]);
  const translate = useTranslate();

  const setNPS = (scoreValue) => {
    if (!score) {
      setScore(scoreValue);
      props.setScore(
        { body: { score: scoreValue } },
        {
          onSuccess() {
            props.refreshContent();
          },
          onFailure() {
            props.refreshContent();
          },
          onRequest() {},
        },
      );
    }
  };

  return (
    <div className={`${props.className}`}>
      {translate("set_score")}
      <div className="large-header">
        {_.map(scores, (scoreValue) => {
          let starStyle;
          if (score >= scoreValue) {
            starStyle = "fas";
          } else {
            starStyle = "fal";
          }
          return (
            <i
              className={`${starStyle} fa-star mr-1`}
              key={scoreValue}
              onClick={() => setNPS(scoreValue)}
              style={{ color: "gold" }}
            />
          );
        })}
      </div>
      {/* {JSON.stringify(scores)} */}
      {/* {props.titleText}: {time} */}
    </div>
  );
};

const mapStateToProps = (state) => ({
  env: state.frontend.env,
  sessionStore: state.customer.session,
});

export default connect(mapStateToProps, {
  setScore: api.post("/account/setScore"),
})(TodayScore);
