import React from "react";

export default {
  locale: "en_US",
  messages: {
    language: "Language",
    country: "Country",
    not_in_country: "Your country is not",
    choose_another_country: "Choose another country",
    buy_astrogotchi: "Buy Astrogotchi",
    buy_astrogotchi_success_text:
      "Congratulate, you have buy Astrogotchi! Check email for activation code!",
    buy_astrogotchi_error_text: "Something went wrong, retry the payment.",

    demo: "Demo",
    sitename: "Astrogotchi",
    write_to_whatsapp: "Write us to WhatsApp",

    locales_unavailable: "No available localizations",
    login: "Account",
    enter_account: "Enter",
    auth: "Your e-mail",
    auth_title: "Login",
    auth_phone: "Your phone",
    auth_email: "Your e-mail",
    auth_code: "Password from the e-mail",
    auth_requestCode: "Continue",
    auth_reRequesCode: "Resend code",
    auth_validateCode: "Enter",
    auth_wrongCode: "Wrong code",
    auth_wrongLogin: "Wrong login",
    auth_codeSent: "Email with password sent to your e-mail.",
    auth_reSend: "Resend via {seconds} sec.",
    auth_required: "Authorization required",
    auth_offer_required: (
      <>
        {"I accept "}
        <a href="/offer" className="text-dark" target="_blank">
          Terms of use
        </a>
      </>
    ),

    faq: "FAQ",
    settings_no_name: "Name not defined",

    settings_and_preferences: "Personal data",
    settings_name: "Your name",
    settings_info_edit: "Change data",
    settings_phone: "Phone number",
    settings_phone_edit: "Change phone number",
    settings_new_phone: "Enter new phone number",
    settings_email: "Email",
    settings_email_edit: "Change e-mail",
    settings_new_email: "New e-mail",

    privacy: "Policy",
    agreement: "Terms of use",
    offer: "Terms of use",

    page_not_found: "Page not found",
    about: "About us",

    settings: "Settings",

    buy_tree: "Посадить дерево",
    buy_tree_header: "Посадить дерево за {treeCost} рублей",
    save_tree_header: "Сохранить лес за {treeCost} ₽",

    your_day: "Main things for today",
    your_horoscope: "Horoscope",
    your_day_horoscope: "Horoscope",
    your_day_today: "{name}, the main things for {date}",
    my_day: "My day",
    about_astrogotchi: "Shop",

    time_limit: "Time to pay",
    payment_failure: "Something went wrong 😱",
    payment_failure_text: "Your payment hadn't complete. Check your payment data",
    payment_failure_button: "Return and try again",
    payment_checking: "Your payment is checking",
    payment_success: "Thank you!",
    payment_success_text: "You have complete the payment 😍",
    payment_success_button: "Посмотреть мой вклад в экологию",

    dateTimeFormat: "DD.MM.YYYY HH:mm",
    dateFormat: "DD.MM.YYYY",
    dateFormatShort: "DD.MM.YY",

    share: "Share",
    product_url_copied: "Link copied",
    price_from: "From",

    soft_title: "Soft",
    soft_description: (
      <>
        This is <b>HTML</b> for soft toys
      </>
    ),
    digital_title: "Digital",
    digital_description: (
      <>
        This is <b>HTML</b> for digital toys
      </>
    ),

    logout: "Logout",

    send: "Send",
    save: "Save",
    edit: "Edit",
    free: "Free",
    refresh: "Refresh",
    reset: "Reset",
    apply: "Apply",
    total: "Total",
    loading: "Loading...",
    more: "more",
    cancel: "Cancel",
    close: "Close",

    loading_hello: "Hello, {name}",

    about_header: "About you",
    about_your_sign: "Your sign",
    About_just_like: "Just like",
    about_just_like: "just like",
    about_you: "you are",
    About_you: "You are",
    about_mean: "and that's mean...",

    love_header: "Love",
    love_description: "Love compatibility",

    preparing_calculation: "Analyzing planets...",
    loading_calculation: "Preparing your forecast for today...",

    and: "and",
    date: "Date",
    time: "Time",
    important: "Important",

    show_details: "Learn more",

    item_code: "Registration code",
    item_stop: "Whoops...",

    your_preferences: "What is important to you?",
    your_name: "Your name",
    your_gender: "Gender",
    name: "Name",
    surname: "Surname",
    birthDate: "Birthdate",
    gender: "Gender",
    gender_male: "Male",
    gender_male_icon: "👨",
    gender_female: "Female",
    gender_female_icon: "👩‍🦰",

    wrong_birthdate: "Wrong birthdate",
    month: "Month",
    month_1: "January",
    month_2: "February",
    month_3: "March",
    month_4: "April",
    month_5: "May",
    month_6: "June",
    month_7: "July",
    month_8: "August",
    month_9: "September",
    month_10: "October",
    month_11: "November",
    month_12: "December",

    month_block_description: "Horoscope for a month",
    soon: "soon",
    digital: "digital",

    zodiac: "Zodiac sign",
    zodiac_aries: "Aries",
    zodiac_taurus: "Taurus",
    zodiac_gemini: "Gemini",
    zodiac_cancer: "Cancer",
    zodiac_leo: "Leo",
    zodiac_virgo: "Virgo",
    zodiac_libra: "Libra",
    zodiac_scorpio: "Scorpio",
    zodiac_sagittarius: "Sagittarius",
    zodiac_capricorn: "Capricorn",
    zodiac_aquarius: "Aquarius",
    zodiac_pisces: "Pisces",

    zodiac_aries_icon: "♈️",
    zodiac_taurus_icon: "♉️",
    zodiac_gemini_icon: "♊️",
    zodiac_cancer_icon: "♋️",
    zodiac_leo_icon: "♌️",
    zodiac_virgo_icon: "♍️",
    zodiac_libra_icon: "♎️",
    zodiac_scorpio_icon: "♏️",
    zodiac_sagittarius_icon: "♐️",
    zodiac_capricorn_icon: "♑️",
    zodiac_aquarius_icon: "♒️",
    zodiac_pisces_icon: "♓️",

    zodiac_aries_code: "&#9800;",
    zodiac_taurus_code: "&#9801;",
    zodiac_gemini_code: "&#9802;",
    zodiac_cancer_code: "&#9803;",
    zodiac_leo_code: "&#9804;",
    zodiac_virgo_code: "&#9805;",
    zodiac_libra_code: "&#9806;",
    zodiac_scorpio_code: "&#9807;",
    zodiac_sagittarius_code: "&#9808;",
    zodiac_capricorn_code: "&#9809;",
    zodiac_aquarius_code: "&#9810;",
    zodiac_pisces_code: "&#9811;",

    friends: "Compatibility",
    friends_today: "Compatibility today",
    friends_common_info: "Compatibility in life",
    friend_add: "Add new",
    friend_name: "Name",
    friend_delete: "Remove the person",
    friend_info_title: "Compatibility {name1} the {sign1} and {name2} the {sign2}",
    friends_empty_text: "Add new person and get known about yours compatibility today",

    complete_button: "Ok, I understand",
    next_day: "Until the new forecast",

    coming_soon: "Coming soon",

    set_score: "Score the forecast",
    users_scores: "Estimation of forecast accuracy",

    cookie_button: "Alright",
    cookie_notice: "This website uses cookies to improve service",

    new_astrogotchi: "Register new Astrogotchi",

    under_construction: "In development",
    under_construction_winter: "This content will appear in 2023",

    feedback: "Feedback",
    feedback_title: "Feedback",
    feedback_all: "Other feedback",

    WRONG_REGISTRATION_CODE: "Wrong code",
    CODE_ALREADY_ACTIVATED: "Code already activated",
    CATEGORY_NOT_FOUND: "Astrogotchi not found",
    WRONG_BIRTHDATE: "Wrong birthdate",
  },
};
