import _ from "lodash";
import React, { Fragment, useState, useEffect } from "react";
import { setLocaleData, setLocaleCountry } from "actions";
import { connect } from "react-redux";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import LocalesValues from "components/standart/LocalesValues";
import { IntlActions, useTranslate } from "react-redux-multilingual";

import { frontendApi, setSuccess } from "reducers/api";

const { api } = frontendApi.actions;

const LocalesSelector = (props) => {
  const translate = useTranslate();
  const { locales, envStore } = props;
  const [currentLocale, setCurrentLocale] = useState({ ...locales.data });
  const [currentCountry, setCurrentCountry] = useState({ ...locales.country });
  const [localeModal, setLocaleModal] = useState(false);
  const [localesDataIsChanged, setLocalesDataIsChanged] = useState(false);
  const [localesUnavailable] = useState(false);
  const [countries, setCountries] = useState([]);

  const { settings = {} } = props.env.www || {};

  useEffect(() => {
    setLocalesDataIsChanged(
      currentLocale._id !== locales.data._id || currentCountry._id !== locales.country._id,
    );
  }, [currentLocale, currentCountry]);

  useEffect(() => {
    // если отсутствует допустимая страна, то возьмем значение по умолчанию
    if (!locales.country?._id) {
      props.getDefaultCountry(
        {},
        {
          onSuccess(country) {
            props.setLocaleCountry(country);
          },
        },
      );
    }
  }, [locales]);

  const saveLocaleData = () => {
    props.setLocaleData(currentLocale);
    props.setLocaleCountry(currentCountry);
    props.setLocale(currentLocale.code);
    setLocaleModal(false);
  };

  useEffect(() => {
    if (!localeModal) {
      props.setLocale(locales.data.code);
    } else {
      setCurrentCountry(locales.country);
      setCurrentLocale(locales.data);
    }
  }, [localeModal]);

  useEffect(() => {
    props.getCountries({}, setSuccess(setCountries));
  }, []);

  useEffect(() => {
    if (_.size(countries) && locales.country?._id) {
      const [country] = countries.filter((country) => country._id === locales.country._id);
      // обновим состояние в контексте при обновлени
      if (country) {
        props.setLocaleCountry(country);
      } else {
        props.setLocaleCountry({});
      }
    }
  }, [countries]);

  return (
    <>
      {localesUnavailable ? (
        <LocalesUnavailableModal />
      ) : (
        <>
          <div className="mt-1" onClick={() => setLocaleModal(true)}>
            {props.children || (
              <span
                className="text-dark standart-header text-decoration-none relative"
                style={{ cursor: "pointer" }}
              >
                {_.upperCase(locales.data.code)}
                {/* {locales.data.icon} */}
              </span>
            )}
          </div>

          <Modal
            isOpen={localeModal}
            centered
            size="md"
            toggle={() => setLocaleModal(false)}
            wrapClassName={"fullscreen-modal"}
          >
            <ModalHeader toggle={() => setLocaleModal(false)}></ModalHeader>
            <ModalBody>
              <div className="row">
                <div className="col-lg-3 col-sm-hidden"></div>
                <div className="col text-center">
                  <div className="large-header mb-3">{translate("language")}</div>

                  {_.map(envStore.locales, (locale, index) => {
                    const activeClass =
                      currentLocale._id === locale._id ? "btn-dark" : "border-grey";
                    return locale.enabled ? (
                      <Fragment key={index}>
                        <span
                          className={`btn mr-2 mb-2 rounded ${activeClass}`}
                          onClick={() => setCurrentLocale(locale)}
                        >
                          <LocalesValues
                            values={locale.localesName}
                            defaultValue={locale.name}
                            localeId={locale._id}
                          />{" "}
                          {locale.icon}
                          {/* {locale.name} */}
                        </span>
                      </Fragment>
                    ) : null;
                  })}

                  {settings.allowSelectCountry ? (
                    <>
                      <div className="large-header my-3">{translate("country")}</div>
                      {_.map(countries, (country, index) => {
                        const activeClass =
                          currentCountry._id === country._id ? "btn-dark" : "border-grey";
                        return (
                          <Fragment key={index}>
                            <span
                              className={`btn mr-2 mb-2 rounded ${activeClass}`}
                              onClick={() => setCurrentCountry(country)}
                            >
                              <LocalesValues
                                values={country.localesName}
                                defaultValue={country.name}
                              />
                            </span>
                          </Fragment>
                        );
                      })}
                    </>
                  ) : null}

                  <div className="my-5">
                    <button
                      className="btn btn-solid"
                      disabled={!localesDataIsChanged}
                      onClick={saveLocaleData}
                    >
                      {translate("save")}
                    </button>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-hidden"></div>
              </div>
            </ModalBody>
          </Modal>
        </>
      )}
    </>
  );
};

const LocalesUnavailableModal = (props) => {
  const translate = useTranslate();
  return (
    <Modal isOpen={true} centered size="ld" wrapClassName={"fullscreen-modal"}>
      <ModalHeader></ModalHeader>
      <ModalBody>
        <div className="row">
          <div className="col-lg-3 col-sm-hidden"></div>
          <div className="col">
            <div className="text-center large-header">{translate("locales_unavailable")}</div>
          </div>
          <div className="col-lg-3 col-sm-hidden"></div>
        </div>
      </ModalBody>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  sessionStore: state.customer.session,
  envStore: state.customer.env,
  env: state.frontend.env,
  locales: state.locales,
});

export default connect(mapStateToProps, {
  setLocaleData,
  setLocaleCountry,
  setLocale: IntlActions.setLocale,
  getCountries: api.get("/countries"),
  getDefaultCountry: api.get("/defaultCountry"),
})(LocalesSelector);
