import _ from "lodash";

const { VK_PIXEL_NUMBER, VK_PRICE_ID } = process.env;
let VK;

function refresh() {
  VK = window.VK;
  if (!VK) {
    setTimeout(refresh, 1000);
  }
}
refresh();

let vkPixel;

export const initPixel = () => {
  if (VK_PIXEL_NUMBER && !vkPixel && VK) {
    vkPixel = VK.Retargeting.Init(VK_PIXEL_NUMBER);
    VK.Retargeting.Hit();
  }
  return vkPixel;
};

export const action = (method, params) => {
  const result = VK_PRICE_ID
    ? vkPixel.ProductEvent(VK_PRICE_ID, method, { ...params })
    : null;

  // console.log("run event", { method, params, result, VK_PRICE_ID, vkPixel });

  return result;
};

export const pageView = () => {
  if (initPixel()) {
    // vkPixel.pageView(); // For tracking page view
  }
};

export const productsToPixel = (products) => {
  const products_id = [];
  let total_price = 0;
  _.map(products, (product) => {
    products_id.push({ id: `${product.ident}` });
    total_price += product.price;
  });
  const currency_code = "RUB";
  const result = { total_price, currency_code, products: products_id };
  return result;
};

export const ViewContent = (...products) => {
  if (initPixel()) {
    const content = productsToPixel(products);
    action("view_product", { ...content });
    // vkPixel.track("ViewContent", { ...content });
  }
};

export const ProductSold = (...products) => {
  if (initPixel()) {
    const content = productsToPixel(products);
    action("purchase", { ...content });
    // vkPixel.track("Purchase", { ...content });
  }
};

export const AddToCart = (...products) => {
  if (initPixel()) {
    const content = productsToPixel(products);
    action("add_to_cart", { ...content });
  }
};

export default initPixel();
