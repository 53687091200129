import _ from "lodash";
import React from "react";
import { connect } from "react-redux";

const GiphyImage = (imageData = {}) => {
  const { data } = imageData;
  return _.size(data) ? (
    <div
      className="giphy-image"
      style={{
        backgroundImage: `url("${data.images.downsized.url}")`,
      }}
    ></div>
  ) : null;
};

export default connect(null, {})(GiphyImage);
