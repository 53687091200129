import _ from "lodash";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import BirthDateCalendar from "components/standart/BirthDateCalendar";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { withTranslate } from "react-redux-multilingual";
import { customerApi } from "reducers/api";

const { api, session } = customerApi.actions;

const AddFriend = (props) => {
  const { translate, systemEnv, successHandler } = props;

  const [name, setName] = useState("");
  const [gender, setGender] = useState(null);
  const [birthDate, setBirthDate] = useState(null);

  const changeName = ({ target }) => setName(target.value);
  // const changeBirthDate = ({ target }) => setBirthDate(target.value);

  const save = () => {
    props.addFriend(
      { body: { name, gender, birthDate } },
      {
        onSuccess(body) {
          props.getUser(
            {},
            {
              onSuccess() {
                setOpenModal(false);
                successHandler(body);
              },
            },
          );
        },
      },
    );
  };

  const [openModal, setOpenModal] = useState(null);
  const toggleModal = () => setOpenModal(!openModal);
  useEffect(() => {
    setName("");
    setGender(null);
    setBirthDate(null);
  }, [openModal]);
  return (
    <div className="mt-4">
      <div className="form-group">
        <div>
          <button type="button" className="btn btn-solid" onClick={toggleModal}>
            {translate("friend_add")}
          </button>
        </div>
        {openModal && (
          <Modal
            centered
            wrapClassName="fullscreen-modal"
            isOpen={openModal}
            size="md"
            toggle={toggleModal}
          >
            <ModalHeader toggle={toggleModal}></ModalHeader>
            <ModalBody>
              <div className="row">
                <div className="col-lg-3 col-sm-hidden" />
                <div className="col">
                  <div className="text-center">
                    <div className="form-group pb-2">
                      <label className="large-header">{translate("friend_name")}</label>
                      <input
                        type="text"
                        className="form-control mb-2 text-center"
                        value={name || ""}
                        onChange={changeName}
                      />
                    </div>

                    <div className="form-group pt-5 pb-2">
                      <label className="large-header">{translate("birthDate")}</label>
                      <BirthDateCalendar {...{ birthDate, setBirthDate }} hideYear={true} />
                    </div>

                    <div className="form-group pt-5 pb-2">
                      <label className="large-header">{translate("gender")}</label>
                      <div>
                        {_.map(systemEnv.genders, (_gender, index) => {
                          const isActive = gender === _gender;
                          const activeClass = isActive ? "btn-dark" : "border-grey";
                          return (
                            <span
                              key={index}
                              className={`btn mr-2 mb-2 rounded ${activeClass}`}
                              onClick={() => setGender(_gender)}
                            >
                              {translate(`gender_${_gender}_icon`)} {translate(`gender_${_gender}`)}
                            </span>
                          );
                        })}
                      </div>
                    </div>

                    <div className="form-group text-center mt-5">
                      <button
                        type="button"
                        className="btn btn-solid"
                        disabled={!(name && birthDate && gender)}
                        onClick={save}
                      >
                        {translate("friend_add")}
                      </button>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-hidden" />
              </div>
            </ModalBody>
          </Modal>
        )}
      </div>
    </div>
  );
};
const mapeStateToProps = (state) => ({
  systemEnv: state.customer.env,
});
export default connect(mapeStateToProps, {
  ...session.actions,
  addFriend: api.post("/account/friends"),
})(withTranslate(AddFriend));
