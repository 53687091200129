import React from "react";
import { Link } from "react-router-dom";

function LogoImage(props) {
  return (
    <>
      <Link to={`/`} className="d-block -float-left my-auto" style={{ textDecoration: "none" }}>
        {/* <img src={`/logo.png`} alt="" className="img-fluid" style={{ height: "40px" }} /> */}
        <div className="-pull-right" style={{ color: "black" }}>
          <div style={{ fontSize: "1.3em", fontFamily: "Arial Black", fontWeight: "bold" }}>
            astrogotchi
          </div>
        </div>
      </Link>
      <div className="clearfix" />
    </>
  );
}

export default LogoImage;
