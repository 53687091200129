import _ from "lodash";
import { useTranslate } from "react-redux-multilingual";
import { Alert } from "reactstrap";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

const BirthDateCalendar = (props) => {
  const { birthDate, setBirthDate } = props;

  const translate = useTranslate();

  const days = _.range(1, 31 + 1);
  const months = _.range(1, 12 + 1);
  const years = _.range(1900, new Date().getFullYear() + 1);

  const [currentDate, setCurrentDate] = useState(null);
  const [currentDay, setCurrentDay] = useState(undefined);
  const [currentMonth, setCurrentMonth] = useState(undefined);
  const [currentYear, setCurrentYear] = useState(undefined);

  const [errorDate, setErrorDate] = useState(null);

  useEffect(() => {
    const date = birthDate ? new Date(birthDate) : new Date();
    setCurrentDay(date.getDate());
    setCurrentMonth(date.getMonth() + 1);
    setCurrentYear(birthDate ? date.getFullYear() : 2000);
  }, []);

  useEffect(() => {
    if (currentDate) {
      const date = moment(currentDate, "YYYY-M-D");
      if (date.isValid() && (props.hideYear || date.toDate() < new Date())) {
        setErrorDate(null);
        setBirthDate(date.toDate());
      } else {
        setBirthDate(null);
        setErrorDate(true);
      }
    }
  }, [currentDate]);

  useEffect(() => {
    if (currentDay && currentMonth && currentYear) {
      setCurrentDate(`${currentYear}-${currentMonth}-${currentDay}`);
    }
  }, [currentDay, currentMonth, currentYear]);

  const changeValue =
    (setValue) =>
    ({ target }) => {
      setValue(target.value);
    };

  const changeYear = changeValue(setCurrentYear);
  const changeMonth = changeValue(setCurrentMonth);
  const changeDay = changeValue(setCurrentDay);

  const options = (source, current, translate) => {
    return source.map((value, index) => (
      <option value={value} key={index}>
        {translate ? translate(value) : value}
      </option>
    ));
  };

  return (
    <div className="px-lg-5">
      <div className="px-lg-5">
        <div className="row text-center -mb-3 px-lg-5">
          {props.hideYear ? <div className="col-2" /> : null}
          <div className="col-3">
            <select className="form-control" value={currentDay} onChange={changeDay}>
              {options(days, currentDay)}
            </select>
          </div>
          <div className="col-5">
            <select className="form-control" value={currentMonth} onChange={changeMonth}>
              {options(months, currentMonth, (value) => translate(`month_${value}`))}
            </select>
          </div>
          {props.hideYear ? null : (
            <div className="col-4">
              <select className="form-control" value={currentYear} onChange={changeYear}>
                {options(years, currentYear)}
              </select>
            </div>
          )}
        </div>
        {errorDate && (
          <Alert className="alert-danger mx-auto my-2">{translate("wrong_birthdate")}</Alert>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {})(BirthDateCalendar);
