import _ from "lodash";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useTranslate } from "react-redux-multilingual";
import { history } from "store";

import AccountInfo from "./info";
// import Phone from "./phone";
import Email from "./email";

import { customerApi } from "reducers/api";
const { session } = customerApi.actions;

const Settings = (props) => {
  const translate = useTranslate();
  const safeLogout = () => {
    // безопасный выход подразумевает закрытие модалки
    // и последующий выход
    props.logout();
    history.push("/");
  };

  return (
    <div>
      <div className="row">
        <div className="col-lg-3 col-hidden"></div>
        <div className="col">
          <div className="text-center">
            <AccountInfo />
            <Email />
            <span className="btn btn-outline mt-4 mb-3" onClick={safeLogout}>
              {translate("logout")}
            </span>
          </div>
        </div>
        <div className="col-lg-3 col-hidden"></div>
      </div>
    </div>
  );
};

export default connect(null, {
  ...session.actions,
})(Settings);
