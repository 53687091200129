import _ from "lodash";
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { Route, Switch } from "react-router-dom";

import qs from "query-string";
import { contentId, friendId, celebrityId } from "components/standart/share-button";
import AboutAstrogotchiBlock from "components/standart/about-block";

import { Helmet } from "react-helmet";
import { scrollTop, LoadingIcon } from "components/standart";
import { useTranslate } from "react-redux-multilingual";
import { customerApi } from "reducers/api";
import Error404 from "../error404";
import ShareContent from "./content";
import ShareFriend from "./friend";
import ShareCelebrity from "./celebrity";

const { api } = customerApi.actions;

const variants = _.invert({ contentId, friendId, celebrityId });
function removeHTML(str) {
  const tmp = document.createElement("DIV");
  tmp.innerHTML = str;
  return tmp.textContent || tmp.innerText || "";
}

const SharedContent = (props) => {
  const { env } = props;
  const params = useParams();
  const translate = useTranslate();

  const { meta } = env.www;

  const [sharedData, setSharedData] = useState(null);

  const [isLoaded, setLoaded] = useState(false);
  const [metaData, setMetaData] = useState({});

  const changeMeta = (data = {}) => {
    setMetaData({ ...metaData, ...data });
  };
  const [currentDescription, setCurrentDescription] = useState(null);

  useEffect(() => {
    if (sharedData) {
      const data = sharedData.content || sharedData.friend || sharedData.celebrityData;
      setMetaData({
        ...metaData,
        description: removeHTML(currentDescription),
        image: _.get(data, "imageData.data.images.downsized.url"),
        video: _.get(data, "imageData.data.images.preview.mp4"),
        still: _.get(data, "imageData.data.images.downsized_still.url"),
      });
    } else {
      setMetaData({});
    }
  }, [sharedData, currentDescription]);

  useEffect(() => {
    // console.log("params is", params, variants);
    if (variants[params.segment]) {
      const query = { [variants[params.segment]]: params.id };
      scrollTop();
      props.getContent(
        { query },
        {
          onSuccess(body) {
            setSharedData(body);
            setLoaded(true);
          },
          onRequest() {
            setLoaded(false);
          },
          onFailure() {
            setSharedData(null);
            setLoaded(true);
          },
        },
      );
    } else {
      setSharedData(null);
      setLoaded(false);
    }
  }, [params]);

  const changeValueHandler = (value) => {
    setCurrentDescription(value);
  };

  return isLoaded ? (
    sharedData ? (
      <>
        {/*SEO Support*/}
        <Helmet>
          <title>{metaData.title}</title>

          <meta property="og:title" content={metaData.title} />
          <meta property="og:description" content={metaData.description} />
          <meta property="og:type" content="article" />
          <meta property="og:image" content={metaData.still} />
          <meta property="og:video" content={metaData.video} />
          <meta property="og:video:url" content={metaData.video} />
          <meta property="og:video:secure_url" content={metaData.video} />
          <meta property="og:video:type" content="video/mp4" />
          {/* <meta name="twitter:image:src" content={metaData.image} /> */}
          {/* <meta name="twitter:image" content={metaData.image} /> */}
        </Helmet>
        <section>
          <div className="container pt-0">
            <div className="row">
              <div className="col-lg-3 col-sm-hidden" />
              <div className="col text-center">
                {/* 
                <Switch>
                  <Route
                    exact
                    path={contentId}
                    component={() => (
                      <ShareContent {...{ sharedData, changeValueHandler, changeMeta }} />
                    )}
                  />
                  <Route
                    exact
                    path={friendId}
                    component={() => (
                      <ShareFriend {...{ sharedData, changeValueHandler, changeMeta }} />
                    )}
                  />
                  <Route
                    exact
                    path={friendId}
                    component={() => (
                      <ShareCelebrity {...{ sharedData, changeValueHandler, changeMeta }} />
                    )}
                  />
                </Switch>
                 */}

                {params.segment === contentId ? (
                  <ShareContent {...{ sharedData, changeValueHandler, changeMeta }} />
                ) : null}
                {params.segment === friendId ? (
                  <ShareFriend {...{ sharedData, changeValueHandler, changeMeta }} />
                ) : null}
                {params.segment === celebrityId ? (
                  <ShareCelebrity {...{ sharedData, changeValueHandler, changeMeta }} />
                ) : null}

                <div className="pt-5" />
                <div className="py-3" />
                <AboutAstrogotchiBlock />
                <div className="py-4" />
              </div>
              <div className="col-lg-3 col-sm-hidden" />
            </div>
          </div>
        </section>
      </>
    ) : (
      <Error404 />
    )
  ) : (
    <div className="text-center py-5">
      <LoadingIcon />
    </div>
  );
};
const mapStateToProps = (state) => ({
  env: state.frontend.env,
});

export default connect(mapStateToProps, {
  getContent: api.get("/shared"),
})(SharedContent);
