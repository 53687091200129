import React, { useEffect } from "react";
import { connect } from "react-redux";
import { withTranslate } from "react-redux-multilingual";
import { metrikaTargetClick } from "services";
import { scrollTop } from "components/standart";
import { Link } from "react-router-dom";
import giphyLogo from "assets/images/powered-giphy.png";

const Footer = (props) => {
  const { translate } = props;

  const { settings = {} } = props.env.www || {};

  return (
    <footer>
      <section className="bg-black">
        <div className="container">
          <div className="standart-header text-center text-white py-4">
            <div
              style={{ fontSize: "1.3em", fontFamily: "Arial Black", fontWeight: "bold" }}
              className="mb-2"
            >
              <Link to="/" onClick={scrollTop} className="text-white text-decoration-none">
                astrogotchi
              </Link>
            </div>

            <div className="my-3" />

            <div style={{ fontSize: "1.8em" }}>
              {settings.instagram && (
                <a
                  href={settings.instagram}
                  className="text-white mr-4"
                  target="_blank"
                  data-id="instagram"
                  onClick={metrikaTargetClick}
                >
                  <i className="fab fa-instagram" />
                </a>
              )}
              {settings.facebook && (
                <a
                  href={settings.facebook}
                  className="text-white mr-4"
                  target="_blank"
                  data-id="facebook"
                  onClick={metrikaTargetClick}
                >
                  <i className="fab fa-facebook" />
                </a>
              )}

              {settings.vk && (
                <a
                  href={settings.vk}
                  className="text-white mr-4"
                  target="_blank"
                  data-id="vk"
                  onClick={metrikaTargetClick}
                >
                  <i className="fab fa-vk" />
                </a>
              )}

              {settings.whatsapp && (
                <a
                  href={settings.whatsapp}
                  className="text-white mr-4"
                  target="_blank"
                  data-id="whatsapp"
                  onClick={metrikaTargetClick}
                >
                  <i className="fab fa-whatsapp" />
                </a>
              )}

              {settings.telegram && (
                <a
                  href={settings.telegram}
                  className="text-white mr-4"
                  target="_blank"
                  data-id="telegram"
                  onClick={metrikaTargetClick}
                >
                  <i className="fab fa-telegram" />
                </a>
              )}

              {settings.email && (
                <a
                  href={`mailto:${settings.email}`}
                  className="text-white"
                  target="_blank"
                  data-id="email"
                  onClick={metrikaTargetClick}
                >
                  <i className="far fa-envelope" />
                </a>
              )}
            </div>
            <div className="my-2" />

            <div className="pt-2">
              <a
                target="_blank"
                href="/offer"
                className="text-white py-1"
                data-id="offer"
                onClick={metrikaTargetClick}
              >
                {translate("offer")}
              </a>
            </div>

            <div className="mt-4">
              <a href="https://giphy.com">
                <img src={giphyLogo} height="30" />
              </a>
            </div>
          </div>
        </div>
        {/* <div className="d-block d-lg-none mobile-menu-space">&nbsp;</div> */}
      </section>
    </footer>
  );
};

const mapStateToProps = (state) => ({
  env: state.frontend.env,
});

export default connect(mapStateToProps, null)(withTranslate(Footer));
