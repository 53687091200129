import React from "react";
import { connect } from "react-redux";

const Col3Container = (props) => {
  return (
    <div className="row">
      <div className="col-lg-3 col-sm-hidden" />
      <div className="col">{props.children}</div>

      <div className="col-lg-3 col-sm-hidden" />
    </div>
  );
};

export default connect(null, {})(Col3Container);
