import React from "react";

import { Route, Switch } from "react-router-dom";
// import Sitemap from "./sitemap";

import Header from "components/common/headers/header";
import Footer from "components/common/footers/footer";

import Layout from "./views/layout";

import Index from "./views";
import Account from "./views/account";
import SharedContent from "./views/share";
import GoPage from "./views/go";
import Demo from "./views/demo";

import SettingsButton from "./views/account/settings/button";

// import Partners from "./views/partners";
// import PartnersLanding from "./views/partners/landing";

import Content from "./views/content";
import Feedback from "./views/feedback";

// import Feedback from "./views/feedback";

const Routes = () => {
  return (
    <Layout>
      <Header />
      <Switch>
        <Route exact path={`/`} component={() => <Index />} />
        <Route exact path={`/account`} component={() => <Account />} />
        <Route exact path={`/demo`} component={() => <Demo />} />
        <Route exact path="/offer" component={() => <Content />} />
        <Route exact path="/share/:segment/:id" component={() => <SharedContent />} />
        <Route exact path="/go" component={() => <GoPage />} />
        <Route exact path="/feedback" component={() => <Feedback />} />
      </Switch>
      <Footer />
    </Layout>
  );
};

export default Routes;
