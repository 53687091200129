import frontendApi from "./frontend";
import customerApi from "./customer";

export const { FILES_API_URL } = process.env;

export { frontendApi, customerApi };
/** ссылка для файлов */
export function fileUrl(fileId, type = "stream") {
  if (fileId) {
    return `${FILES_API_URL}/file_${fileId}/${type}`;
  }
}

// быстрое присваивание результат в указанный сеттер данных
export const setSuccess = (setData) => ({
  onSuccess(body) {
    setData(body);
  },
});
