import _ from "lodash";
import { createStore, applyMiddleware, compose } from "redux";

// middlewares
import thunkMiddleware from "redux-thunk";
import { routerMiddleware } from "react-router-redux";
import logger from "redux-logger";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { createStateSyncMiddleware, initStateWithPrevTab } from "redux-state-sync";

import { createBrowserHistory } from "history";

// Import custom components
import rootReducer, { middlewares as reducersMiddlewares } from "../reducers";
import { apiMiddleware } from "redux-api-middleware";
import { middlewares as frontendApiMiddlewares } from "reducers/api/frontend";
import { middlewares as customerApiMiddlewares } from "reducers/api/customer";

function saveToLocalStorage(state) {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem("state", serializedState);
  } catch (e) {
    console.log(e);
  }
}

function loadFromLocalStorage() {
  try {
    const serializedState = localStorage.getItem("state");
    if (serializedState === null) return undefined;
    return JSON.parse(serializedState);
  } catch (e) {
    console.log(e);
    return undefined;
  }
}

const persistedState = loadFromLocalStorage();
export const history = createBrowserHistory();
const routeMiddleware = routerMiddleware(history);

const middlewares = [
  thunkMiddleware,
  // createStateSyncMiddleware(_.pick(persistConfig, "blacklist")),
  routeMiddleware,
  apiMiddleware,
  ...frontendApiMiddlewares,
  ...customerApiMiddlewares,
  ...reducersMiddlewares,
];

// const persistedReducer = persistReducer(persistConfig, rootReducer);
// const store = createStore(persistedReducer, composeEnhancer(applyMiddleware(...middlewares)));

/**
 * Create a Redux store that holds the app state.
 */
const store = createStore(
  rootReducer, // persistedReducer
  Object.assign({ Intl: { locale: "ru" } }, { ...persistedState }),
  compose(
    applyMiddleware(...middlewares),

    //For working redux dev tools in chrome (https://github.com/zalmoxisus/redux-devtools-extension)
    window.__REDUX_DEVTOOLS_EXTENSION__
      ? window.__REDUX_DEVTOOLS_EXTENSION__()
      : function (f) {
          return f;
        },
  ),
);

initStateWithPrevTab(store);

const unsubscribe = store.subscribe(() => {
  const state = store.getState();
  saveToLocalStorage(state);
});

export const persistor = persistStore(store);

export default store;
