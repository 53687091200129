import _ from "lodash";
import React, { useState, useEffect } from "react";

import { connect } from "react-redux";
import { useTranslate } from "react-redux-multilingual";
import LocalesValues from "components/standart/LocalesValues";
import GiphyImage from "components/standart/giphyImage";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { LoadingIcon } from "components/standart";
import { frontendApi } from "reducers/api";

import ShareButton, { friendId } from "components/standart/share-button";

const { api } = frontendApi.actions;

export const openFriend = "openFriend";

const FriendZodiac = (friend) => {
  const translate = useTranslate();
  return (
    <>
      <span>{friend.name}</span>
      <font className="ml-1 hashTag">
        {`#${translate(`zodiac_${friend.astroSign}`).toLowerCase()}`}{" "}
      </font>
    </>
  );
};

export const FriendsStateIcon = (gradientInfo) => {
  return (
    <>
      <LocalesValues values={gradientInfo.localesName} /> {gradientInfo?.icon}
    </>
  );
};

export const FriendsNamesInfo = (props) => {
  const translate = useTranslate();
  const { customer, friend } = props;
  return (
    <div>
      <FriendZodiac {...customer} /> {translate("and")} <FriendZodiac {...friend} />
    </div>
  );
};

export const FriendsCommonOverlap = (props) => {
  const translate = useTranslate();
  const { overlapInfo } = props;
  return (
    <div>
      <div className="large-header mb-3">{translate("friends_common")}</div>
      <div className="form-group content-html">
        <LocalesValues values={overlapInfo?.localesText} isHtml={true} />
      </div>
    </div>
  );
};

export const FriendsCommonBlock = (props) => {
  const translate = useTranslate();

  const { data, customer, friend, overlapInfo } = props;
  return (
    <>
      <div className="large-header pb-3">{translate("friends_today")}</div>
      {_.size(data?.imageData?.data) ? (
        <div className="mb-3">
          <GiphyImage {...data.imageData} />
        </div>
      ) : null}
      <div className="mb-2">
        <FriendsNamesInfo {...{ customer, friend }} />
      </div>
      <div>
        <div className="content-html">
          {data?.gradientText ? (
            <LocalesValues
              values={data?.gradientText.localesText}
              isHtml={true}
              changeValueHandler={props.changeValueHandler}
            />
          ) : null}
        </div>
        <div className="mt-2 hashTag">
          <span>
            <FriendsStateIcon {...data?.gradientInfo} />
          </span>
        </div>
      </div>
    </>
  );
};

const FriendsBlock = (props) => {
  const { friendsOverlaps = [], account, locales } = props;
  const translate = useTranslate();

  const [friendData, setFriendData] = useState(null);
  const [friendLoading, setFriendLoading] = useState(null);
  const [friendError, setFriendError] = useState(false);

  const [openModal, setOpenModal] = useState(false);
  const toggleModal = () => setOpenModal(!openModal);

  const openInfo = (friendId) => () => {
    setOpenModal(true);
    const localeId = locales.data._id;

    localeId &&
      props.showFriend(
        { query: { localeId, friendId } },
        {
          onSuccess(body) {
            setFriendData(body);
            setFriendLoading(false);
          },
          onRequest() {
            setFriendLoading(true);
            setFriendError(false);
          },
          onFailure({ message }) {
            setFriendLoading(false);
            setFriendError(message);
          },
        },
      );
  };

  useEffect(() => {
    if (!openModal) setFriendData(null);
  }, [openModal]);

  return (
    <div>
      <div className="large-header mb-3">{translate("friends_today")}</div>
      {friendsOverlaps.length
        ? friendsOverlaps.map((friend, index) => {
            return (
              <div
                key={index}
                className="row mb-3"
                onClick={openInfo(friend._id)}
                style={{ cursor: "pointer" }}
              >
                <div className="col text-left my-auto">
                  <FriendZodiac {...friend} />
                </div>
                <div className="col text-right my-auto">
                  {friend.daily ? (
                    <span className="hashTag">
                      <FriendsStateIcon {...friend.daily.gradientInfo} />
                    </span>
                  ) : null}
                </div>
              </div>
            );
          })
        : translate("friends_empty_text")}
      <div className="my-5" />

      {openModal && (
        <Modal
          centered
          wrapClassName="fullscreen-modal"
          isOpen={openModal}
          size="md"
          toggle={toggleModal}
        >
          <ModalHeader toggle={toggleModal}></ModalHeader>
          <ModalBody>
            <div className="row">
              <div className="col-lg-3 col-sm-hidden" />
              <div className="col">
                <div className="text-center">
                  {friendData ? (
                    <div>
                      <FriendsCommonBlock
                        customer={account}
                        friend={friendData.friend}
                        data={friendData.friend.daily}
                        overlapInfo={friendData.overlapInfo}
                      />

                      <div className="form-group text-center mt-5">
                        <ShareButton share={friendId} currentId={friendData.friend.dailyId} />
                      </div>
                    </div>
                  ) : (
                    <LoadingIcon />
                  )}
                </div>
              </div>
              <div className="col-lg-3 col-sm-hidden" />
            </div>
          </ModalBody>
        </Modal>
      )}
    </div>
  );
};
const mapStateToProps = (state) => ({
  locales: state.locales,
});

export default connect(mapStateToProps, {
  showFriend: api.get("/demo/friend"),
})(FriendsBlock);
