import _ from "lodash";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import AboutAstrogotchiBlock from "components/standart/about-block";

import LocalesValues from "components/standart/LocalesValues";

import { Alert } from "reactstrap";
import { history } from "store";

import { withTranslate } from "react-redux-multilingual";
import { customerApi, fileUrl } from "reducers/api";

import { modalId } from "./init";
const { api, session } = customerApi.actions;

const AddItem = (props) => {
  const { translate, sessionStore, category, envStore } = props;
  const { items } = sessionStore;
  const [error, setError] = useState(false);

  useEffect(() => {
    document.getElementById(modalId).scrollIntoView();
  }, []);

  const saveHandler = () => {
    props.addItem(
      { body: { code: _.toUpper(code), categoryId: category._id } },
      {
        onSuccess() {
          props.getUser(
            {},
            {
              onSuccess() {
                history.replace("/account");
              },
            },
          );
        },
        onRequest() {
          setError(false);
        },
        onFailure({ message }) {
          setError(message);
        },
      },
    );
  };

  const [code, setCode] = useState("");
  const changeCode = ({ target }) => setCode(target.value);

  const cancelHandler = () => {
    if (!items.length) {
      props.logout(
        {},
        {
          onSuccess() {
            history.replace("/");
          },
        },
      );
    } else {
      history.replace("/account");
    }
  };

  useEffect(() => {
    const existsCategory = items.filter((item) => item.categoryId === category._id);
    // если сушествующая категория
    if (existsCategory.length) {
      props.resetItem(
        { body: { categoryId: category._id } },
        {
          onSuccess() {
            history.replace("/account");
          },
        },
      );
    }
  }, [items, category]);

  return (
    <div>
      {/* <label className="large-header mb-3">{translate("new_astrogotchi")}</label> */}
      <div className="pb-3" />
      <div className="content-html pb-3">
        <LocalesValues values={envStore.www?.settings?.helpText} isHtml={true} />
      </div>

      {envStore.www?.settings?.helpImageId ? (
        <img
          src={fileUrl(envStore.www?.settings?.helpImageId)}
          width="100%"
          className="pt-3 pb-5"
        />
      ) : null}

      <div className="form-group">
        <label className="large-header mt-2 mb-3">{translate("item_code")}</label>
        <input
          type="code"
          style={{ textTransform: "uppercase" }}
          maxLength={7}
          className="form-control mb-2 text-center"
          value={code}
          onChange={changeCode}
        />
      </div>

      {error ? (
        <Alert className="alert-danger" isOpen={true} toggle={() => setError(false)}>
          {translate(error) || error}
        </Alert>
      ) : (
        <></>
      )}

      <div className="form-group text-center mt-5">
        <button type="button" className="btn btn-solid" disabled={!code} onClick={saveHandler}>
          {translate("send")}
        </button>
      </div>

      <div className="form-group text-center mt-4">
        <button type="button" className="btn btn-outline" onClick={cancelHandler}>
          {translate("cancel")}
        </button>
      </div>

      <div className="pt-3" />
      <div className="mt-5">
        <AboutAstrogotchiBlock />
      </div>
      <div className="pb-5" />
    </div>
  );
};
const mapeStateToProps = (state) => ({
  sessionStore: state.customer.session,
  envStore: state.frontend.env,
});

export default connect(mapeStateToProps, {
  ...session.actions,
  addItem: api.post("/account/addItem"),
  resetItem: api.post("/account/resetItem"),
})(withTranslate(AddItem));
