import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useRouteMatch } from "react-router-dom";

import { Helmet } from "react-helmet";
import ReactHtmlParser from "react-html-parser";
import { scrollTop } from "components/standart";
import { useTranslate } from "react-redux-multilingual";
import { frontendApi } from "reducers/api";
import Error404 from "./error404";

const { api } = frontendApi.actions;

const Content = (props) => {
  const { url, path } = useRouteMatch();
  const { locales } = props;
  const [staticContent, setStaticContent] = useState(null);
  const [isLoaded, setLoaded] = useState(false);

  useEffect(() => {
    scrollTop();
    const localeId = locales.data._id;
    localeId &&
      props.getContent(
        { query: { url, localeId } },
        //
        {
          onSuccess(body) {
            setStaticContent(body);
            setLoaded(true);
          },
        },
      );
  }, [url, locales.data]);

  return isLoaded ? (
    staticContent ? (
      <>
        {/*SEO Support*/}
        <Helmet>
          <title>{staticContent.meta?.title}</title>
          <meta name="description" content={staticContent.meta?.description} />
          <meta property="og:title" content={staticContent.meta?.title} />
          <meta property="og:description" content={staticContent.meta?.description} />
        </Helmet>

        <section>
          <div className="container">
            <div className="standart-header mb-2">
              <b>{staticContent.title}</b>
            </div>
            <div>{ReactHtmlParser(staticContent.data)}</div>
          </div>
        </section>
      </>
    ) : (
      <Error404 />
    )
  ) : null;
};

const mapStateToProps = (state) => ({
  locales: state.locales,
});
export default connect(mapStateToProps, {
  getContent: api.get("/content"),
})(Content);
