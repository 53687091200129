import _ from "lodash";
import React, { useState, useEffect, useRef } from "react";
// import Slider from "react-slick";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Pagination } from "swiper";
import { categoryPhotosUrl, backgroundCover } from "components/standart";
import { useTranslate } from "react-redux-multilingual";

SwiperCore.use([Pagination]);

const CategoryPhotos = (props) => {
  const { category } = props;
  const [photos, setPhotos] = useState([]);
  const translate = useTranslate();

  const [currentIndex, setCurrentIndex] = useState(0);
  const [swiper, setSwiper] = useState(null);

  const slideChangeHandler = (event) => {
    setCurrentIndex(event.realIndex);
    return true;
  };

  useEffect(() => {
    setPhotos(categoryPhotosUrl(category, "image.jpg"));
  }, [category]);

  return (
    <div className="product-photos position-relative">
      {category.digitalOnly ? (
        <div className="soon-corner" style={{ zIndex: 10 }}>
          <span className="text">{translate("digital")}</span>
        </div>
      ) : null}

      <Swiper
        enabled={!props.disabled}
        spaceBetween={0}
        slidesPerView={1}
        onSlideChange={slideChangeHandler}
        pagination={false}
        onSwiper={setSwiper}
        // virtual
      >
        {photos.map((photo, index) => (
          <SwiperSlide key={index}>
            <div className="block-squared" style={backgroundCover(photo)}></div>
          </SwiperSlide>
        ))}
      </Swiper>
      
      {!props.disabled ? (
        <ShowPager swiper={swiper} current={currentIndex} total={category.filesId.length || 1} />
      ) : null}
    </div>
  );
};

const ShowPager = (props) => {
  const { total, current, swiper } = props;

  return (
    <span style={{ fontSize: ".5em" }}>
      {_.range(0, total).map((index) => {
        return (
          <i
            key={index}
            className="fa fa-circle px-1"
            onClick={() => swiper.slideTo(index)}
            style={{ color: index === current ? "black" : "#777" }}
          />
        );
      })}
    </span>
  );
};

export default CategoryPhotos;
