import _ from "lodash";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { LoadingIcon } from "components/standart";
import LocalesValues from "components/standart/LocalesValues";
import ShareButton, { celebrityId } from "components/standart/share-button";
import GiphyImage from "components/standart/giphyImage";
import { useTranslate } from "react-redux-multilingual";
import { frontendApi } from "reducers/api";

const { api } = frontendApi.actions;

export const AboutCelebrityData = (props) => {
  const { celebrityData, customer, astroSignData } = props;
  const translate = useTranslate();
  return (
    <>
      <div className="large-header mb-3">{customer.name}</div>
      {_.size(celebrityData?.imageData?.data) ? (
        <div className="mb-3">
          <GiphyImage {...celebrityData.imageData} />
        </div>
      ) : null}
      <div className="">
        <span>
          {translate("About_you")}{" "}
          <span className="hashTag">
            {"#"}
            {translate(`zodiac_${customer.astroSign}`).toLowerCase()}
          </span>
          {", "}
          {translate("about_just_like")}{" "}
          <LocalesValues values={celebrityData?.celebrity.localesName} />
        </span>
      </div>
      <div className="pb-4" />
      {/* <div className="large-header pt-5 pb-3">{translate("about_header")}</div> */}

      <div className="form-group content-html">
        <LocalesValues
          values={astroSignData?.localesText}
          isHtml={true}
          changeValueHandler={props.changeValueHandler}
        />
      </div>
    </>
  );
};

const AboutBlock = (props) => {
  const translate = useTranslate();
  const { account, locales } = props;

  const [about, setAbout] = useState(null);
  const [loading, setLoading] = useState(null);

  const [openModal, setOpenModal] = useState(null);
  const toggleModal = () => setOpenModal(!openModal);
  useEffect(() => {
    const localeId = locales.data._id;

    props.getAbout(
      { query: { localeId } },
      {
        onSuccess(body) {
          setLoading(false);
          setAbout(body);
        },
        onRequest() {
          setLoading(true);
        },
      },
    );
  }, []);

  return about ? (
    <div>
      <div
        className="px-4 pt-3 pb-4  border rounded"
        onClick={toggleModal}
        style={{ cursor: "pointer" }}
      >
        <div className="large-header mb-3">{account.name}</div>
        <div className="standart-header">
          <span>
            {translate("About_you")}{" "}
            <span className="hashTag">
              {"#"}
              {translate(`zodiac_${account.astroSign}`).toLowerCase()}
            </span>
            {", "}
            {translate("about_just_like")}{" "}
            <LocalesValues values={about.dailyCelebrity?.celebrity.localesName} />
          </span>
        </div>
      </div>
      {openModal && (
        <Modal
          centered
          wrapClassName="fullscreen-modal"
          isOpen={openModal}
          size="md"
          toggle={toggleModal}
        >
          <ModalHeader toggle={toggleModal}></ModalHeader>
          <ModalBody>
            <div className="row">
              <div className="col-lg-3 col-sm-hidden" />
              <div className="col">
                <div className="text-center">
                  <AboutCelebrityData
                    celebrityData={about.dailyCelebrity}
                    customer={account}
                    astroSignData={about.astroSign}
                  />

                  <div className="form-group text-center mt-5 mb-5">
                    <ShareButton share={celebrityId} currentId={about.dailyCelebrity?._id} />
                  </div>

                  {/* 
                    <div className="form-group text-center mt-5">
                      <button
                        type="button"
                        className="btn btn-solid"
                        onClick={() => setOpenModal(null)}
                      >
                        {translate("complete_button")}
                      </button>
                    </div>
                    */}
                </div>
              </div>
              <div className="col-lg-3 col-sm-hidden" />
            </div>
          </ModalBody>
        </Modal>
      )}
    </div>
  ) : (
    <div className="text-center">
      <LoadingIcon />
    </div>
  );
};
const mapeStateToProps = (state) => ({
  locales: state.locales,
});

export default connect(mapeStateToProps, {
  getAbout: api.get("/demo/about"),
})(AboutBlock);
