import _ from "lodash";
import React, { useState, useEffect, Fragment } from "react";
import { connect, useSelector } from "react-redux";
import { LocalesSelector } from "containers";
import LocalesValues from "components/standart/LocalesValues";

import { useTranslate } from "react-redux-multilingual";
import { Modal, ModalBody, ModalHeader } from "reactstrap";

import CategoryPhotos from "./photos";
// import BuyOnline from "./buy";
import { CategoryPrices } from "./prices";

export const CategoryModal = (props) => {
  const { category, toggleModal } = props;
  const { locales } = useSelector((state) => state);
  const { env } = useSelector((state) => state.frontend);
  const { settings = {} } = env.www;
  const translate = useTranslate();
  const size = "50px";
  return (
    <Modal
      size="md"
      centered={true}
      wrapClassName="fullscreen-modal"
      isOpen={true}
      toggle={toggleModal}
    >
      <ModalHeader toggle={toggleModal}></ModalHeader>
      <ModalBody>
        <div className="row">
          <div className="col-lg-3 col-sm-hidden" />
          <div className="col text-center">
            <div className="row">
              <div className="col-lg-6 col-sm-12">
                <CategoryPhotos {...{ category }} />
              </div>
              <div className="col-lg-6 col-sm-12">
                {/* <ItemImage {...{ category }} style={{ width: size, height: size }}  /> */}
                <div className="large-header my-3">
                  <LocalesValues values={category.localesName} defaultValue={category.name} />
                </div>
                <div className="content-html">
                  <LocalesValues values={category.description} isHtml={true} />
                </div>
                {category.notInSale ? (
                  <div className="my-3">
                    <div className="font-weight-bold">{translate("coming_soon")}</div>
                  </div>
                ) : (
                  <div>
                    <div className="my-3">
                      <CategoryPrices {...category} />
                      {/* 
                      <b>
                        <CategoryPrice values={category.prices} />
                      </b>
                      <span className="text-secondary text-strike">
                        <CategoryPrice values={category.oldPrices} className="ml-3"/>
                      </span>
                    </div>
                    {category.digitalOnly ? (
                      <BuyOnline category={category} />
                    ) : (
                      // <button className="btn btn-solid">Купить</button>
                      <MarketPlaceButtons category={category} />
                    )}
                    */}
                    </div>
                  </div>
                )}
                {settings.allowSelectCountry ? (
                  <div className="mt-3">
                    {translate("not_in_country")}{" "}
                    <LocalesValues values={locales.country.localesName} />
                    {"?"}
                    <LocalesSelector>
                      <span className="url">{translate("choose_another_country")}</span>
                    </LocalesSelector>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-sm-hidden" />
        </div>
      </ModalBody>
    </Modal>
  );
};

export const MarketPlaceButtons = (props) => {
  const { category } = props;
  const { locales } = useSelector((state) => state);
  const { env } = useSelector((state) => state.customer);
  const translate = useTranslate();
  console.log(locales, env);
  // const { env } = useSelector((state) => state.frontend);
  // const { settings = {} } = env.www;
  const [buttons, setButtons] = useState([]);
  // const buttons = ["ozonUrl", "wildberriesUrl", "yandexMarketUrl"];
  useEffect(() => {
    const marketplaces = _.keyBy(env.marketplaces, "_id");
    const urls = _.keyBy(category.urls, "marketplaceId");
    const result = [];
    _.map(locales.country.marketplacesId, (marketplaceId) => {
      const marketplace = marketplaces[marketplaceId];
      const url = urls[marketplaceId];
      if (marketplace && url) {
        result.push({ marketplace, url });
      }
    });
    setButtons(result);
  }, [locales, env, category]);
  return buttons.length ? (
    <>
      {buttons.map((button, index) => {
        const { marketplace, url } = button;
        return (
          <div className="mb-4" key={index}>
            <a
              href={url.url}
              target="_blank"
              className={`btn btn-solid`}
              style={{
                // backgroundColor: button.bgColor,

                // background-image: -webkit-linear-gradient(30deg, $theme-yellow 50%, transparent 50%);
                backgroundImage: `linear-gradient(30deg, ${marketplace.bgColor} 50%, transparent 50%)`,
                borderColor: marketplace.bgColor,
                color: `${marketplace.fontColor}`,
              }}
            >
              <LocalesValues values={marketplace.localesName} />
            </a>
          </div>
        );
      })}
    </>
  ) : (
    <div className="font-weight-bold">{translate("coming_soon")}</div>
  );
};

const mapStateToProps = (state) => ({
  env: state.customer.env,
  // locales: state.locales,
});

export default connect(mapStateToProps, {})(CategoryModal);
