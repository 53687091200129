import React from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import Auth from "./index";
import { useTranslate } from "react-redux-multilingual";

const AuthModal = (props) => {
  const translate = useTranslate();

  return (
    <Modal
      isOpen={true}
      wrapClassName="fullscreen-modal"
      centered
      size="md"
      toggle={props.toggleModal}
    >
      <ModalHeader toggle={props.toggleModal}></ModalHeader>
      <ModalBody>
        <div className="row">
          <div className="col-lg-3 col-sm-hidden" />
          <div className="col">
            {props.children}
            <Auth />
          </div>
          <div className="col-lg-3 col-sm-hidden" />
        </div>
      </ModalBody>
    </Modal>
  );
};

export default AuthModal;
