import React from "react";
import noImg from "assets/images/no-photo.png";
import { fileUrl } from "reducers/api";

const ItemImage = (props) => {
  const { category } = props;
  return (
    <img
      className="rounded-circle -border -border-1"
      src={category.avatarFileId ? fileUrl(category.avatarFileId) : noImg}
      {...props}
    />
  );
};

export default ItemImage;
