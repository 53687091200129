import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useTranslate } from "react-redux-multilingual";

import { FriendsCommonBlock } from "../account/data/friends";

const ShareFriend = (props) => {
  const translate = useTranslate();
  const sharedData = props.sharedData.friend;
  const overlapInfo = props.sharedData.overlapInfo;
  const customer = props.sharedData.customer;
  const { changeValueHandler } = props;
  useEffect(() => {
    const description = `${customer.name} #${translate(
      `zodiac_${customer.astroSign}`,
    ).toLowerCase()} ${translate("and")} ${sharedData.friend.name} #${translate(
      `zodiac_${sharedData.friend.astroSign}`,
    ).toLowerCase()}`;

    props.changeMeta({ title: translate("friends_today"), description });
  }, [props.sharedData]);
  return (
    <div>
      <FriendsCommonBlock
        customer={customer}
        friend={sharedData.friend}
        data={sharedData}
        overlapInfo={overlapInfo}
        // changeValueHandler={changeValueHandler}
      />
    </div>
  );
};

export default connect()(ShareFriend);
