import apiClass from "./init";
const items = new apiClass();

items.actions = {
  // общие данные для сайта
  getCategories: items.get("/categories", {
    success(state, body) {
      return { ...state, categories: body };
    },
  }),
};

export default items;
