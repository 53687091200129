import React from "react";
import { connect } from "react-redux";
import ItemImage from "./ItemImage";

const AccountEmoji = (props) => {
  const { category, size = "30px" } = props;

  return (
    <span className={`${props.className}`}>
      <div className="items-images">
        <span className="item small">
          {category ? (
            <ItemImage category={category} style={{ width: size, height: size }} />
          ) : null}
        </span>
      </div>
    </span>
  );
};

const mapStateToProps = (state) => ({
  // assistant: state.customer.assistant,
});

export default connect(mapStateToProps, {})(AccountEmoji);
