import _ from "lodash";

import * as types from "../constants/ActionTypes";
import "react-toastify/dist/ReactToastify.min.css";

// установить сообщение по умолчанию для иконки мессенджера
export const setWhatsAppMessage = (message) => (dispatch) => {
  dispatch({
    type: types.SET_WHATSAPP_MESSAGE,
    message,
  });
};

export const clearWhatsAppMessage = () => (dispatch) => {
  dispatch({
    type: types.SET_WHATSAPP_MESSAGE,
    message: null,
  });
};

// localeId selector
export const setLocaleData = (localeData) => (dispatch) => {
  dispatch({
    type: types.SET_LOCALE_DATA,
    localeData,
  });
};

export const setLocaleCountry = (country) => (dispatch) => {
  dispatch({
    type: types.SET_LOCALE_COUNTRY,
    country,
  });
};

// navigation actions
export const setNavi = (url) => (dispatch) => {
  dispatch({
    type: types.SET_NAVI,
    url,
  });
};

export const resetNavi = () => (dispatch) => {
  dispatch({
    type: types.RESET_NAVI,
  });
};

export const setHeaderSpaceHeight = (headerHeight) => (dispatch) => {
  dispatch({
    type: types.SET_HEADER_HEIGHT,
    headerHeight,
  });
};
