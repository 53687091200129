/* изменение номера телефона у пользователя 
работает следующим образом:
1) пользователь вводит номер телефона
2) на стороне сервера проверяется, что такой номер не занят
- если номер занят, то выдается сообщение об ошибке
- если номер свободен, то отправляется смс код с подтверждением
3) пользователь отправляет полученный код, если проверка успешна, то 
старый логин блокируется (удаляется?), а новый номер привязывается за текущим пользователем
*/
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import NumberFormat from "react-number-format";

import { withTranslate } from "react-redux-multilingual";
import LoginForm from "views/auth/form";
import { phoneFormat } from "components/standart";
import { customerApi } from "reducers/api";
const { api, session } = customerApi.actions;

const Phone = (props) => {
  const { translate, sessionStore } = props;

  const confirmHandler = (req, res) => {
    props.validateCode(req, {
      ...res,
      onSuccess() {
        setOpenModal(false);
      },
    });
  };
  const [openModal, setOpenModal] = useState(false);
  const toggleModal = () => setOpenModal(!openModal);
  useEffect(() => {
    props.getUser();
  }, [openModal]);

  return (
    <div>
      <div className="form-group">
        <div>
          {/* <NumberFormat displayType="text" format={phoneFormat} value={sessionStore.login.value} /> */}
          {sessionStore.login.value}
          <span className="url ml-2" onClick={toggleModal}>
            <i className="fa fa-edit mr-1" />
            {translate("edit")}
          </span>
        </div>
        {openModal && (
          <Modal
            wrapClassName="fullscreen-modal"
            centered={true}
            isOpen={openModal}
            size="md"
            toggle={toggleModal}
          >
            <ModalHeader toggle={toggleModal}></ModalHeader>
            <ModalBody>
              <div className="text-center ">
                <div className="row">
                  <div className="col-lg-3 col-sm-hidden"></div>
                  <div className="col">
                    <div className="large-header mb-3">{translate("settings_new_email")}</div>
                    {/* <div>{translate("settings_new_email")}</div> */}

                    <LoginForm
                      type={"email"}
                      requestHandler={props.requestCode}
                      confirmHandler={confirmHandler}
                      skipAcceptOffer={true}
                    />
                  </div>
                  <div className="col-lg-3 col-sm-hidden"></div>
                </div>
              </div>
            </ModalBody>
            <ModalFooter>
              {/* 
                            <button type="button" className="btn btn-solid" onClick={save}>
                                {translate("save")}
                            </button>
                             */}
            </ModalFooter>
          </Modal>
        )}
      </div>
    </div>
  );
};
const mapeStateToProps = (state) => ({
  sessionStore: state.customer.session,
});
export default connect(mapeStateToProps, {
  ...session.actions,
  requestCode: api.post("/account/login/request"),
  validateCode: api.post("/account/login/confirm"),
})(withTranslate(Phone));
