import * as FBPixel from "./facebook";
import * as VKPixel from "./vk";
import * as GTMPixel from "./google";

/*
const action = (name) => {
  return {
    [name]: (...args) => {
      FBPixel[name](...args);
      VKPixel[name](...args);
    },
  };
};
*/

const actions = {
  ViewContent: (...args) => {
    FBPixel.ViewContent(...args);
    VKPixel.ViewContent(...args);
    GTMPixel.ViewContent(...args);
  },

  ProductSold: (...args) => {
    FBPixel.ProductSold(...args);
    VKPixel.ProductSold(...args);
    GTMPixel.ProductSold(...args);
  },

  AddToCart: (...args) => {
    FBPixel.AddToCart(...args);
    VKPixel.AddToCart(...args);
    GTMPixel.AddToCart(...args);
  },
};

export default { ...actions };

export { FBPixel, VKPixel };
