/**
 * генератор запросов к СloClo API
 * возвращает класс, который собирает структуру для генерации actions, reducer и middleware
 *
 */
import _ from "lodash";
import { combineReducers } from "redux";
import { push } from "react-router-redux";

// здесь будут уже сконфигурированные редюсеры
import { SUCCESS, REQUEST, FAILURE } from "./init";
import customerReducers from "./reducers";
import session from "./session";


export default customerReducers;

// название токена
export const accessToken = "token_customer";

// типовой редюсер для API
export function reducer(state = {}, action) {
  const { payload } = action;
  switch (action.type) {
    case SUCCESS:
      return { ...state, body: payload.body };

    case FAILURE:
      return {
        ...state,
        error: _.get(payload, "response.message") || payload.statusText,
      };

    case REQUEST:
      return { ...state, timestamp: new Date() };

    default:
      return state;
  }
}

export const customerApiReducer = combineReducers({
  api: reducer,
  ...customerReducers.reducers,
});
// export const { frontendApi }Reducer = reducer;

export const customerApiMiddleware = (store) => (next) => (action) => {
  const result = next(action);
  const { payload } = action;

  // если по какой-то причине вернулся 401, то полностью закроем сессию и удалим токен
  if (401 === _.get(payload, "status")) {
    console.log(new Date(), "this is logout???");
    store.dispatch(session.actions.logout());
    store.dispatch(push("/"));
  }

  // если по какой-то причине вернулся 409, то полностью обновим страницу
  // это означает, что умерла кука сессии, или сломался отпечаток клиента
  if (409 === _.get(payload, "status")) {
    // document.location.reload();
  }

  if (_.get(payload, "message") === "Failed to fetch") {
    console.log("enable lock screen");
  }

  return result;
};

export const middlewares = [customerApiMiddleware, ...customerReducers.middlewares];
