import React, { useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { getLocaledValue } from "components/standart/LocalesValues";
import { useTranslate } from "react-redux-multilingual";
import { AboutCelebrityData } from "views/account/data/aboutBlock";

const ShareCelebrity = (props) => {
  const translate = useTranslate();
  const { locales } = useSelector((state) => state);

  const { celebrityData, astroSignData, customer } = props.sharedData;
  useEffect(() => {
    const description = `${translate("About_you")} #${translate(
      `zodiac_${customer.astroSign}`,
    ).toLowerCase()}, ${translate("about_just_like")} ${getLocaledValue(
      celebrityData.celebrity.localesName,
      locales.data?._id,
    )}`;

    props.changeMeta({ title: customer.name, description });
  }, [props.sharedData]);
  return (
    <div>
      <AboutCelebrityData {...{ celebrityData, customer, astroSignData }} />
    </div>
  );
};

export default connect()(ShareCelebrity);
