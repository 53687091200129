import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Settings from "./index";

const SettingsButton = (props) => {
  const { sessionStore } = props;
  const { account } = sessionStore;
  const [showSettings, setShowSettings] = useState(false);
  const openSettings = () => {
    setShowSettings(true);
  };

  const closeSettings = () => {
    setShowSettings(false);
  };

  return account ? (
    <>
      <span>
        <span className="btn" onClick={openSettings}>
          <i className="far fa-cog h5 p-0 m-0" />
        </span>
      </span>
      {showSettings && <Settings {...{ closeSettings }} />}
    </>
  ) : null;
};

const mapStateToProps = (state) => ({
  sessionStore: state.customer.session,
});
export default connect(mapStateToProps, {})(SettingsButton);
