import _ from "lodash";
import React, { useState, useEffect } from "react";
import { Link as a } from "react-router-dom";
import { connect } from "react-redux";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { history } from "store";

import { useTranslate } from "react-redux-multilingual";
import Auth from "views/auth";
import { counterValue } from "components/standart";
import { metrikaTargetClick } from "services";
import AccountEmoji from "components/standart/account-emoji";

const UserLogin = (props) => {
  const translate = useTranslate();
  const { sessionStore } = props;
  const [loginModal, setLoginModal] = useState(false);

  //*
  useEffect(() => {
    if (loginModal) {
      if (sessionStore?.account) {
        setLoginModal(false);
        history.push("/account");
      }
    }
  }, [sessionStore, loginModal]);
  // */

  return (
    <>
      {sessionStore.account ? (
        <div className="">
          <a
            href={`/account`}
            className="text-dark text-decoration-none standart-header relative"
            data-id="account"
            onClick={metrikaTargetClick}
          >
            {props.children || (
              <>
                <AccountEmoji category={sessionStore.currentItem?.category} size="30px" />
              </>
            )}
          </a>
        </div>
      ) : (
        <>
          <div className="mt-1" onClick={() => setLoginModal(true)}>
            {props.children || (
              <a
                className="text-dark standart-header text-decoration-none relative"
                style={{ cursor: "pointer" }}
              >
                <i className="far fa-sign-in" />
              </a>
            )}
          </div>

          <Modal
            isOpen={loginModal}
            centered
            size="md"
            toggle={() => setLoginModal(false)}
            wrapClassName={"fullscreen-modal"}
          >
            <ModalHeader toggle={() => setLoginModal(false)}></ModalHeader>
            <ModalBody>
              <div className="row">
                <div className="col-lg-3 col-sm-hidden"></div>
                <div className="col">
                  <div className="text-center large-header">{translate("auth_email")}</div>
                  <Auth />
                </div>
                <div className="col-lg-3 col-sm-hidden"></div>
              </div>
            </ModalBody>
          </Modal>
        </>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  sessionStore: state.customer.session,
});

export default connect(mapStateToProps, {})(UserLogin);
