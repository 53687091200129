import _ from "lodash";
import { connect } from "react-redux";
import moment from "moment";
import React, { useState, useEffect, useRef } from "react";
import { useTranslate } from "react-redux-multilingual";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import AccountEmoji from "./account-emoji";

export const LoadingModal = (props) => {
  const { account, currentItem, astroSign, totalTimeout } = props;
  const translate = useTranslate();
  const dateFormat = translate("dateFormat");
  const [helpText, setHelpText] = useState("preparing_calculation");
  const { settings = {} } = props.env.www || {};

  const timeoutChangeTitle = useRef(false);
  useEffect(() => {
    timeoutChangeTitle.current = setTimeout(
      () => setHelpText("loading_calculation"),
      totalTimeout / 2,
    );
  }, []);

  return account.name && astroSign ? (
    <Modal size="md" centered={true} wrapClassName="fullscreen-modal" isOpen={true}>
      <ModalHeader></ModalHeader>
      <ModalBody>
        <div className="text-center pt-5">
          <div className="position-relative">
            <div style={{ height: "230px", fontSize: "230px" }}>
              {/* <i className="fa fa-sync fa-spin" /> */}
              <i className="fa fal fa-spinner-third fa-spin"></i>
            </div>
            <div
              style={{
                position: "absolute",
                marginLeft: "auto",
                marginRight: "auto",
                top: 82,
                left: 0,
                right: 0,
                textAlign: "center",
              }}
            >
              <AccountEmoji category={currentItem?.category} size="180px" />
            </div>
          </div>
          <div className="large-header pt-5 mt-5">
            {translate("loading_hello", { name: account.name })}
          </div>
          {/* 
          <div className="py-2" />
          <div>
            {translate(`About_you`)}
            <span className="hashTag mt-4">
              {" #"}
              {translate(`zodiac_${astroSign}`).toLowerCase()}
            </span>
          </div>
          */}
          <div className="py-3" />
          <div>{translate(helpText, { today: moment().format(dateFormat) })}</div>
          <div className="py-3" />
          <div className="hashTag">v {settings.versionNumber}</div>
        </div>
      </ModalBody>
    </Modal>
  ) : null;
};

const mapStateToProps = (state) => ({
  env: state.frontend.env,
});

export default connect(mapStateToProps, {})(LoadingModal);
