/**
 * генератор запросов к СloClo API
 * возвращает класс, который собирает структуру для генерации actions, reducer и middleware
 *
 */
import _ from "lodash";
import { combineReducers } from "redux";

// здесь будут уже сконфигурированные редюсеры
import { SUCCESS, REQUEST, FAILURE } from "./init";
import frontendReducers from "./reducers";

export default frontendReducers;

// название токена
export const accessToken = "token_frontend";

// типовой редюсер для API
export function reducer(state = {}, action) {
  const { payload } = action;
  switch (action.type) {
    case SUCCESS:
      return { ...state, body: payload.body };

    case FAILURE:
      return {
        ...state,
        error: _.get(payload, "response.message") || payload.statusText,
      };

    case REQUEST:
      return { ...state, timestamp: new Date() };

    default:
      return state;
  }
}

export const frontendApiReducer = combineReducers({
  api: reducer,
  ...frontendReducers.reducers,
});

export const frontendApiMiddleware = (store) => (next) => (action) => {
  const result = next(action);
  const { payload } = action;

  if (_.get(payload, "message") === "Failed to fetch") {
    console.log("enable lock screen");
  }

  return result;
};

export const middlewares = [frontendApiMiddleware, ...frontendReducers.middlewares];
