import apiClass from "./init";
const assistant = new apiClass();

assistant.actions = {
  // получить текущий тип астрогочи по типу или по контексту
  initAssist: assistant.get("/initAssist", {
    request() {
      return { _last_date: new Date() };
    },
    success(state, body) {
      return { ...body, _last_date: new Date() };
    },
  }),
  // получить текущий тип астрогочи по типу или по контексту
  resetAssistant: assistant.get("/account/allowedCategories", {
    request() {
      return { _reset: true };
    },
    success(state, body) {
      const [first = { _reset: true }] = body;
      return { ...first };
    },
    failure() {
      return {};
    },
  }),
};

export default assistant;
