import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { Modal, ModalBody, ModalHeader } from "reactstrap";

import qs from "query-string";
import { history } from "store";

import { scrollTop, LoadingIcon } from "components/standart";
import { useTranslate } from "react-redux-multilingual";
import { customerApi } from "reducers/api";
import AssistantBlock from "components/standart/assistantBlock";
import { Alert } from "reactstrap";
import Auth from "views/auth";
import _ from "lodash";

import AddItem from "./addItem";
import { modalId } from "./init";

const { api } = customerApi.actions;
export const alias = "alias";

const GoPage = (props) => {
  const translate = useTranslate();
  const { sessionStore } = props;
  const location = useLocation();

  const [error, setError] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [currentCategory, setCurrentCategory] = useState(null);

  useEffect(() => {
    scrollTop();
  }, []);

  useEffect(() => {
    const query = qs.parse(location.search);
    if (query[alias]) {
      props.findCategory(
        { query },
        {
          onSuccess(body) {
            setLoading(false);
            setCurrentCategory(body);
            // history.replace("/account");
          },
          onRequest() {
            setLoading(true);
          },
          onFailure({ message }) {
            setLoading(false);
            setError(message);
          },
        },
      );
    } else {
      history.replace("/");
    }
  }, [location.search]);

  const closeModal = () => {
    history.push("/");
  };

  return (
    <Modal
      size="md"
      centered={true}
      toggle={closeModal}
      wrapClassName="fullscreen-modal"
      isOpen={true}
      id={modalId}
    >
      <ModalHeader toggle={closeModal}></ModalHeader>
      <ModalBody>
        <div className="row">
          <div className="col-lg-3 col-sm-hidden" />
          <div className="col">
            <div className="text-center">
              {isLoading ? (
                <LoadingIcon />
              ) : (
                <>
                  {error && <Alert className="alert-danger">{translate(error) || error}</Alert>}
                  {currentCategory ? (
                    <>
                      {sessionStore.account ? (
                        <>
                          <AddItem category={currentCategory} />
                        </>
                      ) : (
                        <>
                          <div className="py-3" />
                          <AssistantBlock {...currentCategory} />
                          <div className="py-3" />
                          <div className="text-center">
                            <div className="large-header">{translate("auth_email")}</div>
                            <Auth />
                          </div>
                          <div className="my-4" />
                        </>
                      )}
                      <Link to={"/demo"} className="btn btn-outline">
                        {translate("demo")}
                      </Link>
                    </>
                  ) : null}
                </>
              )}
            </div>
          </div>
          <div className="col-lg-3 col-sm-hidden" />
        </div>
      </ModalBody>
    </Modal>
  );
};
const mapStateToProps = (state) => ({
  env: state.frontend.env,
  sessionStore: state.customer.session,
  // assistant: state.customer.assistant,
});

export default connect(mapStateToProps, {
  findCategory: api.get("/findCategory"),
  // ...assistant.actions,
})(GoPage);
