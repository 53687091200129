import _ from "lodash";
import TagManager from "react-gtm-module";
const { NODE_ENV } = process.env;
const { GOOGLE_TAG_MANAGER_NUMBER } = process.env;

let googlePixel;
export const initPixel = () => {
  if (GOOGLE_TAG_MANAGER_NUMBER && !googlePixel && NODE_ENV === "production") {
    googlePixel = TagManager.dataLayer;
  }
  return googlePixel;
};

export const action = (event, data) => {
  // /*
  const result = googlePixel({
    dataLayer: { event, ecommerce: data },
  });

  return result;
  // */
};

export const pageView = () => {
  if (initPixel()) {
    // vkPixel.pageView(); // For tracking page view
  }
};

export const productsToPixel = (products, withSummary) => {
  const items = [];
  let transaction_id;
  const currency = "RUB";
  let value = 0;
  const affiliation = "Online Store";
  _.map(products, (product) => {
    transaction_id = product.order_id;
    items.push({
      item_id: `${product.ident}`,
      price: product.price,
      item_brand: product.brand.title,
      quantity: "1",
    });
    value += product.price;
  });

  const result = { items };
  if (withSummary) {
    Object.assign(result, { currency, value, affiliation, transaction_id });
  }
  return result;
};

export const ViewContent = (...products) => {
  if (initPixel()) {
    const content = productsToPixel(products);
    action("view_item", { ...content });
    // vkPixel.track("ViewContent", { ...content });
  }
};

export const ProductSold = (...products) => {
  if (initPixel()) {
    const content = { purchase: productsToPixel(products, true) };

    action("purchase", { ...content });
    // vkPixel.track("Purchase", { ...content });
  }
};

export const AddToCart = (...products) => {
  if (initPixel()) {
    const content = productsToPixel(products);
    action("add_to_cart", { ...content });
  }
};

export default initPixel();
