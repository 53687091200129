import _ from "lodash";
import ReactPixel from "react-facebook-pixel";
const { FACEBOOK_PIXEL_NUMBER } = process.env;

const initPixel = () => {
  if (FACEBOOK_PIXEL_NUMBER) {
    const advancedMatching = {}; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
    const options = {
      autoConfig: true, // set pixel's autoConfig
      // debug: true, // enable logs
    };
    ReactPixel.init(FACEBOOK_PIXEL_NUMBER, advancedMatching, options);
    return ReactPixel;
  }
};

export const pageView = () => {
  if (initPixel()) {
    ReactPixel.pageView(); // For tracking page view
  }
};

const productsToPixel = (products) => {
  const content_type = "product";
  const content_ids = [];
  let value = 0;
  _.map(products, (product) => {
    content_ids.push(`${product.ident}`);
    value += product.price;
  });
  const currency = "RUB";
  const result = { value, currency, content_type, content_ids };
  return result;
};

export const ViewContent = (...products) => {
  if (initPixel()) {
    const content = productsToPixel(products);
    ReactPixel.track("ViewContent", { ...content });
  }
};

export const ProductSold = (...products) => {
  if (initPixel()) {
    const content = productsToPixel(products);
    ReactPixel.track("Purchase", { ...content });
  }
};

export const AddToCart = (...products) => {
  if (initPixel()) {
    const content = productsToPixel(products);
    ReactPixel.track("AddToCart", { ...content });
  }
};
