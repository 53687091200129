import _ from "lodash";
import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { withTranslate } from "react-redux-multilingual";
import { Helmet } from "react-helmet";
import { useRouteMatch, Switch, Route } from "react-router-dom";

import DataBlock from "./data";
import { LoadingIcon, scrollTop } from "components/standart";
import { frontendApi } from "reducers/api";
// import AssistantBlock from "components/standart/assistantBlock";

import Auth from "views/auth";

import { setNavi } from "actions";
const { api } = frontendApi.actions;

const DemoAccount = (props) => {
  const { path } = useRouteMatch();
  const { translate, locales } = props;
  const { meta = {} } = props.env.www;
  const [demoData, setDemoData] = useState(null);

  useEffect(() => {
    scrollTop();
  }, []);

  useEffect(() => {
    scrollTop();
    const localeId = locales.data._id;
    localeId &&
      props.getDemo(
        { query: { localeId } },
        //
        {
          onSuccess(body) {
            setDemoData(body);
          },
        },
      );
  }, [locales.data]);

  const {
    title = translate("demo_title"),
    description = translate("demo_description"),
    keywords = "",
  } = meta.demo || {};

  return (
    <Switch>
      <Route exact path={path}>
        <Helmet>
          <title>{title}</title>
        </Helmet>

        {demoData ? (
          <>
            <DataBlock {...demoData} {...props} />
          </>
        ) : (
          <div className="text-center py-5">
            <LoadingIcon />
          </div>
        )}
      </Route>
    </Switch>
  );
};

const mapStateToProps = (state) => ({
  locales: state.locales,
  env: state.frontend.env,
  // assistant: state.customer.assistant,
});

export default withTranslate(
  connect(mapStateToProps, {
    setNavi,
    getDemo: api.get("/demo"),
  })(DemoAccount),
);
