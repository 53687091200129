import _ from "lodash";
import React from "react";
import { connect } from "react-redux";
import LocalesValues from "components/standart/LocalesValues";

import ItemImage from "./ItemImage";

const AssistantBlock = (assistant) => {
  // const { assistant } = props;

  return (
    <div className="text-center mb-3">
      <div className="items-images">
        <span className="item small">
          <ItemImage category={assistant} />
        </span>
      </div>
      <div className="large-header mt-3">
        <LocalesValues values={assistant.greetingHeader} />
      </div>
      <div className="py-3 content-html">
        <LocalesValues values={assistant.greetingText} isHtml={true} />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  // ...
  sessionStore: state.customer.session,
});

export default connect(mapStateToProps, {})(AssistantBlock);
