/** полные reducer-ы для всех API вызовов
 * собираются из типовых вызовов метода `reducer()` соответствующих объектов
 */
import auth, { middleware as authMiddleware } from "./auth";
import env from "./env";
import assistant from "./assistant";
import session from "./session";
import api from "./api";

const modulesToReducers = (modules) => {
  const reducers = {};
  const middlewares = [authMiddleware];
  for (let ident in modules) {
    Object.assign(reducers, { [ident]: modules[ident].reducer() });
    middlewares.push(modules[ident].middleware());
  }
  return { reducers, middlewares };
};

const actions = { auth, session, env, api, assistant };
export default { actions, ...modulesToReducers(actions) };
