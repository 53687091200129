import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import LocalesValues from "components/standart/LocalesValues";
import { useTranslate } from "react-redux-multilingual";

import { history } from "store";

const AboutAstrogotchiBlock = (props) => {
  const { env } = props;
  const translate = useTranslate();
  const { settings } = env.www;
  return (
    <div
      className="px-4 py-3  border rounded"
      style={{ cursor: "pointer" }}
      onClick={() => history.push("/")}
    >
      <div className="large-header mb-3">{translate("about_astrogotchi")}</div>
      <div className="standart-header">
        <LocalesValues values={settings?.shareScreenText} isHtml={true} />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  env: state.frontend.env,
});

export default connect(mapStateToProps, {})(AboutAstrogotchiBlock);
