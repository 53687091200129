import _ from "lodash";
import React, { useState, useEffect, Fragment } from "react";
import { useSelector } from "react-redux";
import LocalesValues from "components/standart/LocalesValues";
import { showMoney } from "components/standart";

import { useTranslate } from "react-redux-multilingual";

import BuyOnline from "./buy";

export const CategoryPrices = (category) => {
  const translate = useTranslate();
  return (
    <div>
      {category.notInSale ? (
        <div className="font-weight-bold">{translate("coming_soon")}</div>
      ) : (
        <div>
          <CategoryCountryPrices category={category} />
        </div>
      )}
    </div>
  );
};

export const MarketPlaceButtons = (props) => {
  const { category } = props;
  const { locales } = useSelector((state) => state);
  const { env } = useSelector((state) => state.customer);
  const translate = useTranslate();
  const [buttons, setButtons] = useState([]);
  const [prices, setPrices] = useState([]);
  // const buttons = ["ozonUrl", "wildberriesUrl", "yandexMarketUrl"];
  useEffect(() => {
    const marketplaces = _.keyBy(env.marketplaces, "_id");
    const urls = _.keyBy(category.urls, "marketplaceId");
    const result = [];
    _.map(locales.country.marketplacesId, (marketplaceId) => {
      const marketplace = marketplaces[marketplaceId];
      const url = urls[marketplaceId];
      if (marketplace && url) {
        result.push({ marketplace, url });
      }
    });
    setButtons(result);
  }, [locales, env, category]);
  return buttons.length ? (
    <>
      {buttons.map((button, index) => {
        const { marketplace, url } = button;
        return (
          <div className="mb-4" key={index}>
            <a
              href={url.url}
              target="_blank"
              className={`btn btn-solid`}
              style={{
                // backgroundColor: button.bgColor,

                // background-image: -webkit-linear-gradient(30deg, $theme-yellow 50%, transparent 50%);
                backgroundImage: `linear-gradient(30deg, ${marketplace.bgColor} 50%, transparent 50%)`,
                borderColor: marketplace.bgColor,
                color: `${marketplace.fontColor}`,
              }}
            >
              <LocalesValues values={marketplace.localesName} />
            </a>
          </div>
        );
      })}
    </>
  ) : null;
  // <div className="font-weight-bold">{translate("coming_soon")}</div>
};

export const CategoryCountryPrices = (props) => {
  const { category } = props;
  const { locales } = useSelector((state) => state);
  const { env } = useSelector((state) => state.frontend);
  const translate = useTranslate();

  const [prices, setPrices] = useState([]);
  const [groupedPrices, setGroupedPrices] = useState([]);
  // const buttons = ["ozonUrl", "wildberriesUrl", "yandexMarketUrl"];
  useEffect(() => {
    const { currencyId } = locales.country;
    if (currencyId) {
      const activePrices = [];
      const itemTypePrices = _.keyBy(
        _.filter(category.prices, (price) => price.currencyId === currencyId),
        "itemTypeId",
      );
      _.map(env?.www?.types, (type) => {
        if (itemTypePrices[type._id]) {
          const groupKey = type.isDigital ? "digital" : "soft";
          activePrices.push({ ...itemTypePrices[type._id], type, groupKey });
        }
      });
      setPrices(activePrices);
      setGroupedPrices(_.groupBy(activePrices, "groupKey"));
    }
  }, [locales, env, category]);

  return prices.length ? (
    <>
      {_.map(_.keys(groupedPrices), (groupKey, index) => {
        const pricesInGroup = _.sortBy(groupedPrices[groupKey], "value");
        return (
          <div key={index}>
            {/* <div className="large-header">{translate(`${groupKey}_title`)}</div> */}
            {/* <div className="my-3" /> */}
            <div className="standart-header">
              <LocalesValues values={_.get(env.settings, `${groupKey}Toys`)} isHtml={true} />
            </div>
            <div className="my-3" />
            {pricesInGroup.map((price, index) => {
              return (
                <div className="standart-header" key={index}>
                  <b className="mr-2">
                    <CategoryPrice values={[{ ...price, value: price.value }]} />
                  </b>
                  {price.oldValue ? (
                    <span className="text-strike mr-2">
                      <CategoryPrice
                        values={[{ ...price, value: price.oldValue }]}
                        className="ml-3"
                      />
                    </span>
                  ) : null}
                  (<LocalesValues values={price.type.localesName} isHtml={false} />)
                  <div className="my-3" />
                  {price.type.isDigital ? <BuyOnline {...{ category, price }} /> : null}
                </div>
              );
            })}
            <div className="my-4" />
            {groupKey === "soft" ? <MarketPlaceButtons category={category} /> : null}
            <div className="my-5" />
          </div>
        );
      })}
    </>
  ) : null;
  // <div className="font-weight-bold">{translate("coming_soon")}</div>
};

export const CategoryPrice = (props) => {
  const { env } = useSelector((state) => state.customer);
  const { locales } = useSelector((state) => state);
  const [value, setValue] = useState(props.value);
  const [symbol, setSymbol] = useState(null);
  useEffect(() => {
    const { currencyId } = { ...locales.country };
    const [price] = _.filter(props.values, (elem) => elem.currencyId === currencyId);
    if (price) {
      setValue(price.value);
      const [currency] = _.filter(env.currencies, (currency) => currency._id === currencyId);
      currency && setSymbol(currency.symbol);
    }
  }, [locales]);
  return value && symbol ? showMoney(value, symbol) : null;
};
