import _ from "lodash";
import React, { useState, useEffect, Fragment } from "react";
import { connect, useSelector } from "react-redux";
import ReactHtmlParser from "react-html-parser";
import { useLocation, useRouteMatch } from "react-router-dom";
import LocalesValues from "components/standart/LocalesValues";
import qs from "query-string";
import { LoadingIcon, showMoney } from "components/standart";
import { useTranslate } from "react-redux-multilingual";
import { frontendApi } from "reducers/api";
import { history } from "store";
import CategoryModal, { MarketPlaceButtons } from "./modal";
import BuyOnline from "./buy";
import CategoryPhotos from "./photos";

const { api, items } = frontendApi.actions;

export const itemsAnchorName = "items_achnor";

const CategoriesIndex = (props) => {
  const translate = useTranslate();
  const location = useLocation();
  const { env } = useSelector((state) => state.frontend);
  const { landings = {} } = env.www;
  const { title = "", content = "" } = landings?.shop || {};

  const [categories, setCategories] = useState([]);
  const [category, setCategory] = useState([]);
  const [isLoaded, setLoaded] = useState(false);
  const [modalOpen, setModalOpen] = useState(null);
  const toggleModal = () => setModalOpen(!modalOpen);

  const openInfo = (category) => {
    history.replace(`?buy=${category.alias}`);
  };

  useEffect(() => {
    const { buy } = qs.parse(location.search);
    if (buy && categories.length > 0) {
      const [allowedCategory] = categories.filter(({ alias }) => alias === buy);
      if (allowedCategory) {
        setCategory(allowedCategory);
        setModalOpen(true);
        // openInfo(category);
      }
    }
  }, [location.search, categories]);

  useEffect(() => {
    if (modalOpen === false) {
      history.replace("?");
    }
  }, [modalOpen]);

  useEffect(() => {
    props.getCategories(
      {},
      {
        onSuccess(body) {
          setCategories(body);
          setLoaded(true);
        },
      },
    );
  }, []);

  return isLoaded ? (
    <>
      <section className={`${props.className}`}>
        <a name="items" id={itemsAnchorName} />
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-sm-hidden" />
            <div className="col text-center">
              <div className="large-header">{ReactHtmlParser(title)}</div>
              <div className="standart-header  mt-3">{ReactHtmlParser(content)}</div>
              <div className="my-5" />

              <div className="row">
                {categories.map((category, index) => {
                  return (
                    <Fragment key={index}>
                      <div
                        className="col-12 col-md-6 mb-5"
                        style={{ cursor: "pointer" }}
                        onClick={() => openInfo(category)}
                      >
                        <CategoryPhotos {...{ category }} disabled={true} />
                        <div className="my-3" />
                        <div className="font-weight-bold">
                          <LocalesValues
                            values={category.localesName}
                            defaultValue={category.name}
                          />
                        </div>
                        <div>
                          {category.notInSale ? (
                            <b>{translate("coming_soon")}</b>
                          ) : (
                            <div>
                              <CategoryCountryMinPrice category={category} />
                              {/* 
                              <b className="">
                                <CategoryPrice values={category.prices} />
                              </b>

                              <span className="text-strike">
                                <CategoryPrice values={category.oldPrices} className="ml-3" />
                              </span>
  
                              <div className="mt-4">
                                {category.digitalOnly ? (
                                  <BuyOnline category={category} />
                                ) : (
                                  <MarketPlaceButtons category={category} />
                                )}
                              </div>
                              */}
                            </div>
                          )}
                        </div>
                      </div>
                    </Fragment>
                  );
                })}
              </div>
            </div>
            <div className="col-lg-3 col-sm-hidden" />
          </div>
        </div>
        {modalOpen && <CategoryModal {...{ category, toggleModal }} />}
      </section>
    </>
  ) : (
    <div className="text-center">
      <LoadingIcon />
    </div>
  );
};

export const CategoryCountryMinPrice = (props) => {
  const { category } = props;
  const { locales } = useSelector((state) => state);
  const { env } = useSelector((state) => state.frontend);
  const translate = useTranslate();

  const [minPrice, setMinPrice] = useState(null);
  // const buttons = ["ozonUrl", "wildberriesUrl", "yandexMarketUrl"];
  useEffect(() => {
    const { currencyId } = locales.country;

    if (currencyId) {
      const activePrices = [];
      const itemTypePrices = _.keyBy(
        _.filter(category.prices, (price) => price.currencyId === currencyId),
        "itemTypeId",
      );
      _.map(env?.www?.types, (type) => {
        if (itemTypePrices[type._id]) {
          activePrices.push({ ...itemTypePrices[type._id], type });
        }
      });
      setMinPrice(_.sortBy(activePrices, "value").shift());
    }
  }, [locales, env, category]);
  return minPrice ? (
    <>
      {[minPrice].map((price, index) => {
        return (
          <div className="standart-header font-weight-normal" key={index}>
            {translate("price_from")} <CategoryPrice values={[{ ...price, value: price.value }]} />
          </div>
        );
      })}
    </>
  ) : null;
  // <div className="font-weight-bold">{translate("coming_soon")}</div>
};

export const CategoryPrice = (props) => {
  const { env } = useSelector((state) => state.customer);
  const { locales } = useSelector((state) => state);
  const [value, setValue] = useState(null);
  const [symbol, setSymbol] = useState(null);
  useEffect(() => {
    const { country } = locales;
    const { currencyId } = { ...locales.country };
    const [price] = _.filter(props.values, (elem) => elem.currencyId === currencyId);
    if (price) {
      setValue(price.value);
      const [currency] = _.filter(env.currencies, (currency) => currency._id === currencyId);
      setSymbol(currency.symbol);
    }
  }, [locales]);
  return value && symbol ? (
    <span className={props.className}>{showMoney(value, symbol)}</span>
  ) : null;
};

export default connect(null, {
  ...items.actions,
})(CategoriesIndex);
