import _ from "lodash";
import React, { useState, useEffect, Fragment } from "react";
import { connect } from "react-redux";
import ReactHtmlParser from "react-html-parser";

import { Accordion, AccordionSummary, AccordionDetails } from "./accordion";

import { frontendApi, setSuccess } from "reducers/api";
import { useToggle } from "react-use";

const { api } = frontendApi.actions;

const Faq = (props) => {
  const [faq, setFaq] = useState([]);
  const { type } = props;

  useEffect(() => {
    // вызовем параметризацию редюсера
    props.getFaq({ query: { type } }, setSuccess(setFaq));
  }, [type]);

  const FaqElem = (faqElem) => {
    const [isOpen, toggleOpen] = useToggle(faqElem.index < 0);
    return (
      <Accordion
        expanded={isOpen}
        onChange={toggleOpen}
        style={{ borderBottom: "1px solid #96969696" }}
      >
        <AccordionSummary expandIcon={<i className="fal fa-angle-down" />} className="border-0">
          <b>
            {faqElem.key} {faqElem.title}
          </b>
        </AccordionSummary>
        <AccordionDetails>
          <div className="faq-content">
            {faqElem.question ? (
              <div>
                {ReactHtmlParser(faqElem.question)}
                <hr />
              </div>
            ) : null}

            {ReactHtmlParser(faqElem.answer)}
            <br />
          </div>
        </AccordionDetails>
      </Accordion>
    );
  };

  return faq.length > 0 ? (
    <div>
      {!props.noHeader ? <div className="text-center large-header mb-2">Вопросы и ответы</div> : null}
      <div className="mb-3">
        {_.map(faq, (faqElem, index) => {
          return (
            <Fragment key={index}>
              <FaqElem {...faqElem} index={index} />
            </Fragment>
          );
        })}
      </div>
    </div>
  ) : null;
};

export default connect(null, {
  getFaq: api.get("/faq"),
})(Faq);
