import apiClass from "./init";
const env = new apiClass();

env.actions = {
  // общие данные для сайта
  system: env.get("/env", {
    success(state, body) {
      return { ...body };
    },
  }),
};

export default env;
