import _ from "lodash";
import { SET_NAVI, RESET_NAVI, SET_HEADER_HEIGHT } from "constants/ActionTypes";

export default (state = {}, action) => {
  _.defaults(state, {
    url: null,
    headerSpaceHeight: 0,
  });
  switch (action.type) {
    // установить сообщение по умолчанию
    case SET_NAVI:
      return {
        ...state,
        url: action.url,
      };
    case RESET_NAVI:
      return { ...state, url: null };
    case SET_HEADER_HEIGHT:
      return {
        ...state,
        headerSpaceHeight: action.headerHeight,
      };
    default:
  }
  return state;
};
