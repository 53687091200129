import _ from "lodash";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { Alert } from "reactstrap";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { withTranslate } from "react-redux-multilingual";
import { customerApi, frontendApi } from "reducers/api";
import LocalesValues from "components/standart/LocalesValues";
import BirthDateCalendar from "components/standart/BirthDateCalendar";

const { api, session } = customerApi.actions;
const { api: frontend } = frontendApi.actions;

const AccountInfo = (props) => {
  const { translate, sessionStore, systemEnv } = props;
  const { account, items } = sessionStore;

  const [preferencesList, setPreferencesList] = useState([]);
  const [preferencesId, setPreferencesId] = useState([]);
  const [error, setError] = useState(null);
  const [name, setName] = useState(null);
  const [gender, setGender] = useState(null);
  const [birthDate, setBirthDate] = useState(null);

  const changeName = ({ target }) => setName(target.value);
  // const changeBirthDate = ({ target }) => setBirthDate(target.value);

  const save = () => {
    props.saveSettings(
      { body: { name, gender, birthDate, preferencesId } },
      {
        onRequest() {
          setError(null);
        },
        onFailure({ message }) {
          setError(message);
        },
        onSuccess() {
          props.getUser(
            {},
            {
              onSuccess() {
                setOpenModal(false);
              },
            },
          );
        },
      },
    );
  };

  const [openModal, setOpenModal] = useState(null);
  const toggleModal = () => setOpenModal(!openModal);
  const togglePreference = (preferenceId) => () => {
    if (preferencesId.includes(preferenceId)) {
      setPreferencesId(preferencesId.filter((preference) => preference !== preferenceId));
    } else {
      setPreferencesId([...preferencesId, preferenceId]);
    }
  };

  useEffect(() => {
    if (!openModal && items?.length && !account.name) setOpenModal(!account.name);
    if (openModal) {
      props.getPreferences(
        {},
        {
          onSuccess(body) {
            setPreferencesList(body);
          },
        },
      );
      setName(account.name);
      setGender(account.gender);
      if (account.birthDate) {
        setBirthDate(moment(account.birthDate).format("YYYY-MM-DD"));
      }
      setPreferencesId(account.preferencesId);
    }
  }, [account, items, openModal]);

  return (
    <div className="mt-2">
      <div className="form-group">
        <div>
          {account.name || account.surname ? (
            <>
              <>{translate("settings_and_preferences")}</>
              {/* 
              {`${account.name}`}
              {", "}
              {`${moment(account.birthDate).format("DD.MM.YYYY")}`}
              {", "}
              {`${translate(`gender_${account.gender}`).toLowerCase()}`}
              */}
            </>
          ) : (
            <>{translate("settings_no_name")}</>
          )}

          <span className="url ml-2" onClick={toggleModal}>
            <i className="fa fa-edit mr-1" />
            {translate("edit")}
          </span>
        </div>
        {openModal && (
          <Modal
            centered
            wrapClassName="fullscreen-modal"
            isOpen={openModal}
            size="md"
            toggle={account.name ? toggleModal : null}
          >
            <ModalHeader toggle={account.name ? toggleModal : null}>
              {/* {translate(account.name ? "settings_info_edit" : "settings_info_edit")} */}
            </ModalHeader>
            <ModalBody>
              <div className="row">
                <div className="col-lg-3 col-sm-hidden" />
                <div className="col">
                  <div className="text-center">
                    {error && (
                      <Alert className="alert-danger mx-auto" toggle={() => setError(null)}>
                        {translate(error) || error}
                      </Alert>
                    )}

                    <div className="form-group pb-2">
                      <label className="large-header mb-3">{translate("your_name")}</label>
                      <input
                        type="text"
                        className="form-control text-center"
                        value={name}
                        onChange={changeName}
                      />
                    </div>

                    <div className="form-group pt-5 pb-2">
                      <label className="large-header mb-3">{translate("birthDate")}</label>
                      <BirthDateCalendar {...{ birthDate, setBirthDate }} />
                    </div>

                    <div className="form-group pt-5 pb-2">
                      <label className="large-header mb-3">{translate("your_gender")}</label>
                      <div>
                        {_.map(systemEnv.genders, (_gender, index) => {
                          const isActive = gender === _gender;
                          const activeClass = isActive ? "btn-dark" : "border-grey";
                          return (
                            <span
                              key={index}
                              className={`btn mr-2 rounded ${activeClass}`}
                              onClick={() => setGender(_gender)}
                            >
                              {translate(`gender_${_gender}`)} {translate(`gender_${_gender}_icon`)}
                            </span>
                          );
                        })}
                      </div>
                    </div>

                    {/* 
                    <div className="form-group mt-5">
                      <label className="large-header mb-3">{translate("your_preferences")}</label>
                      <div>
                        {preferencesList.map((preference, index) => {
                          const isActive = preferencesId.includes(preference._id);
                          const activeClass = isActive ? "btn-dark" : "border-grey";
                          return (
                            <span
                              key={index}
                              className={`btn mr-2 mb-2 rounded ${activeClass}`}
                              onClick={togglePreference(preference._id)}
                            >
                              <LocalesValues
                                values={preference.localesName}
                                defaultValue={preference.name}
                              />
                            </span>
                          );
                        })}
                      </div>
                    </div>
                     */}

                    <div className="form-group text-center mt-5">
                      <button
                        type="button"
                        className="btn btn-solid"
                        disabled={!(name && birthDate && gender)}
                        onClick={save}
                      >
                        {translate("save")}
                      </button>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-hidden" />
              </div>
            </ModalBody>
          </Modal>
        )}
      </div>
    </div>
  );
};
const mapeStateToProps = (state) => ({
  sessionStore: state.customer.session,
  systemEnv: state.customer.env,
});
export default connect(mapeStateToProps, {
  ...session.actions,
  saveSettings: api.post("/account"),
  getPreferences: frontend.get("/preferences"),
})(withTranslate(AccountInfo));
