import _ from "lodash";
import React, { useState, useEffect, useRef, Fragment } from "react";
import { connect, useSelector } from "react-redux";
import LocalesValues from "components/standart/LocalesValues";
import { customerApi } from "reducers/api";
import qs from "query-string";
import { useTranslate } from "react-redux-multilingual";
import { history } from "store";

import { useLocation, useRouteMatch } from "react-router-dom";
import AuthModal from "views/auth/modal";
import Auth from "views/auth";
import { Alert, Modal, ModalBody, ModalHeader } from "reactstrap";
import AssistantBlock from "components/standart/assistantBlock";

import { LoadingIcon } from "components/standart";
const { api } = customerApi.actions;
const checkPayment = "checkPayment";

// купить онлайн
const BuyOnline = (props) => {
  const translate = useTranslate();

  const { category, price, locales, env, sessionStore } = props;
  const location = useLocation();
  const [buttons, setButtons] = useState([]);

  useEffect(() => {
    const query = qs.parse(location.search);

    if (query[checkPayment] && category.alias) {
      // тут собственно проверка платежа
      props.checkPayment(
        { params: { paymentId: query[checkPayment] } },
        {
          onRequest() {
            history.replace(
              `?${qs.stringify({ ...query, [checkPayment]: undefined }, { encode: false })}`,
            ); // удалим упоминание проверку платежей
            setChecking(true);
            setError(null);
            setSuccess(null);
          },
          onSuccess(body) {
            setChecking(false);
            setSuccess(body.status === "paid");
            setError(body.status !== "paid");
          },
          onFailure({ message }) {
            setChecking(false);
            setError(message);
          },
        },
      );
    }
  }, [location.search]);

  useEffect(() => {
    const paymentsServices = _.keyBy(env.paymentsServices, "_id");
    const result = [];
    _.map(locales.country.paymentsServicesId, (serviceId) => {
      const service = paymentsServices[serviceId];
      if (service) {
        result.push(service);
      }
    });
    setButtons(result);
  }, [locales, env]);

  const [error, setError] = useState(null);
  const [checking, setChecking] = useState(null);
  const [success, setSuccess] = useState(null);
  const [processing, setProcesing] = useState(null);
  const [authRequired, setAuthRequired] = useState(null);
  const [emailRequired, setEmailRequired] = useState(null);

  const [receiptEmail, setReceiptEmail] = useState("");
  const [makeGift, setMakeGift] = useState(null);
  const [giftEmail, setGiftEmail] = useState("");
  const [emailProps, setEmailProps] = useState({});

  const changeValue =
    (setState) =>
    ({ target }) => {
      const { value = "" } = target;
      setState(value);
    };

  const lastPaymentServiceId = useRef(null);

  useEffect(() => {
    if (success) {
      goToCategoryPage();
    }
  }, [success]);

  const goToCategoryPage = () => {
    document.location.assign(`/go?alias=${category.alias}`);
  };

  const prepareProcessPayment = (paymentServiceId) => {
    // тут следует проверка на наличие емейла и показ
    lastPaymentServiceId.current = paymentServiceId;
    if (sessionStore.account) {
      // setEmailRequired(true);
      startProcessPayment(lastPaymentServiceId.current);
    } else {
      setAuthRequired(true);
    }
  };

  const startProcessPayment = (paymentServiceId) => {
    //
    const query = { ...qs.parse(location.search), [checkPayment]: `{paymentId}` };
    const host = [document.location.origin, document.location.pathname].join("");
    const redirectURL = [host, qs.stringify(query, { encode: false })].join("?");

    const body = {
      paymentServiceId,
      price,
      localeId: locales.data._id,
      categoryId: category._id,
      countryId: locales.country._id,
      redirectURL,
      ...emailProps,
    };

    if (sessionStore.account) {
      // очистим какие-то старые данные
      // тут следует сделать проверку на аккаунт, и только потом запустить оплату
      props.buyMemsters(
        { body },
        {
          onSuccess(body) {
            setProcesing(null);
            lastPaymentServiceId.current = null;
            document.location = body.result.paymentUrl; // тут переход на новый адрес
          },
          onRequest() {
            setChecking(false);
            setSuccess(false);
            setError(false);
            setProcesing(paymentServiceId);
          },
          onFailure({ message }) {
            setError(message);
            setProcesing(null);
          },
        },
      );
    } else {
      lastPaymentServiceId.current = paymentServiceId;
      setAuthRequired(true);
    }
  };

  // если была успешная обязательная авторизация
  useEffect(() => {
    if (authRequired && lastPaymentServiceId.current && sessionStore.account) {
      setAuthRequired(false);
      prepareProcessPayment(lastPaymentServiceId.current);
    }
    setReceiptEmail(sessionStore.email?.value || ""); // установим емейл из профиля
  }, [sessionStore.account, authRequired]);

  useEffect(() => {
    if (!processing) {
      setAuthRequired(false);
    }
  }, [processing]);

  const saveEmailProps = (value) => {
    setEmailProps({ ...emailProps, ...value });
  };

  return (
    <>
      {checking && (
        <div className="py-2">
          <LoadingIcon className="mr-2" />
          {translate("payment_checking")}
        </div>
      )}
      {error && (
        <Alert className="alert-danger mx-auto" toggle={() => setError(null)}>
          {/* {error} */}
          {translate("buy_memsters_error_text")}
        </Alert>
      )}

      {success && false && (
        <Alert className="alert-success mx-auto" toggle={() => setError(null)}>
          {translate("buy_memsters_success_text")}
        </Alert>
      )}

      {/* 
      {success && (
        <Modal isOpen={true} wrapClassName="fullscreen-modal">
          <ModalHeader toggle={goToMemsterPage}></ModalHeader>
          <ModalBody>
            <div className="text-center">
              <div className="standart-header">{translate("buy_memsters_success_text")}</div>
              <div className="my-5" />
              <button className="btn btn-solid" onClick={goToMemsterPage}>
                {translate("buy_memsters_success_button")}
              </button>
            </div>
          </ModalBody>
        </Modal>
      )}
       */}

      {buttons.map((button, index) => {
        return (
          <div className="mb-5" key={index}>
            <button
              target="_blank"
              className={`btn btn-solid`}
              // onClick={() => startProcessPayment(button._id)}
              onClick={() => prepareProcessPayment(button._id)}
            >
              <LocalesValues values={button.localesName} />
            </button>
          </div>
        );
      })}
      {emailRequired && (
        <AuthModal
          toggleModal={() => {
            setEmailRequired(false);
          }}
        >
          <div className="text-center">
            {/* <div className="large-header">{translate("payment_email_required")}</div> */}

            <div className="large-header">{translate("payment_email_receipt")}</div>
            <div className="form-group">
              <input
                value={receiptEmail}
                type="email"
                placeholder={translate("email")}
                className={`form-control text-center`}
                onChange={changeValue(setReceiptEmail)}
                onBlurCapture={() => saveEmailProps({ receiptEmail })}
              />
            </div>
            <div className="form-group mt-5">
              <div className="pretty p-icon p-fill mr-2">
                <input
                  id="makeGift"
                  value={true}
                  type="checkbox"
                  onChange={() => setMakeGift(!makeGift)}
                  checked={makeGift}
                />
                <div className="state">
                  <i className="icon fa fa-check" />
                  <label />
                </div>
              </div>

              <label className="d-inline" htmlFor="makeGift">
                {translate("payment_make_gift")}
              </label>
            </div>
            {makeGift ? (
              <div>
                <div className="large-header">{translate("payment_email_gift")}</div>
                <div className="form-group">
                  <input
                    value={giftEmail}
                    type="email"
                    placeholder={translate("email")}
                    className={`form-control text-center`}
                    onChange={changeValue(setGiftEmail)}
                    onBlurCapture={() => saveEmailProps({ giftEmail })}
                  />
                </div>
              </div>
            ) : null}

            <button
              className="btn btn-solid"
              disabled={!receiptEmail && (!makeGift || !giftEmail)}
              onClick={() => startProcessPayment(lastPaymentServiceId.current)}
            >
              {translate("payment_continue")}
            </button>
          </div>
        </AuthModal>
      )}
      {authRequired && (
        <AuthModal
          toggleModal={() => {
            setProcesing(false);
          }}
        >
          <div className="text-center">
            <AssistantBlock {...category} />

            <div className="large-header">{translate("auth_email")}</div>
            <Auth />
          </div>
        </AuthModal>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  sessionStore: state.customer.session,
  env: state.customer.env,
  locales: state.locales,
});

export default connect(mapStateToProps, {
  buyMemsters: api.post("/account/payments/buyAstrogotchi"),
  checkPayment: api.get("/account/payments/payment_{paymentId}/check"),
})(BuyOnline);
