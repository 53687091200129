import React from "react";

export default {
  locale: "ru",
  messages: {
    language: "Язык",
    country: "Страна",
    not_in_country: "Твоя страна не",
    choose_another_country: "Выбери другую страну",
    buy_astrogotchi: "Купить Астрогочи",
    buy_astrogotchi_success_text:
      "Поздравляем, ты купил Астрогочи! Проверь почту, туда придет код активации!",
    buy_astrogotchi_error_text: "Что-то пошло не так. Повтори оплату.",

    demo: "Демо",
    sitename: "Астрогочи",
    write_to_whatsapp: "Напишите нам в WhatsApp",

    locales_unavailable: "Нет доступных локализаций",
    login: "Аккаунт",
    enter_account: "Войти",
    auth: "Твой e-mail",
    auth_title: "Вход",
    auth_phone: "Ваш телефон",
    auth_email: "Твой e-mail",
    auth_code: "Пароль из письма",
    auth_requestCode: "Продолжить",
    auth_reRequesCode: "Повторная отправка",
    auth_validateCode: "Войти",
    auth_wrongCode: "Неверный код",
    auth_wrongLogin: "Неверный логин",
    auth_codeSent: "Письмо с паролем у тебя на e-mail",
    auth_reSend: "Повторить через {seconds} сек.",
    auth_required: "Необходимо авторизоваться",
    auth_offer_required: (
      <>
        {"Я принимаю "}
        <a href="/offer" className="text-dark" target="_blank">
          пользовательское соглашение
        </a>
      </>
    ),

    faq: "FAQ",
    settings_no_name: "Имя не указаны",

    settings_and_preferences: "Твои данные",
    settings_name: "Ваше имя",
    settings_info_edit: "Изменить данные",
    settings_phone: "Номер телефона",
    settings_phone_edit: "Изменить номер телефона",
    settings_new_phone: "Введите новый номер",
    settings_email: "Email",
    settings_email_edit: "Изменить e-mail",
    settings_new_email: "Новый e-mail",

    privacy: "Политика безопасности",
    agreement: "Пользовательское соглашение",
    offer: "Пользовательское соглашение",

    page_not_found: "Страница не найдена",
    about: "О нас",

    settings: "Настройки",

    buy_tree: "Посадить дерево",
    buy_tree_header: "Посадить дерево за {treeCost} рублей",
    save_tree_header: "Сохранить лес за {treeCost} ₽",

    your_day: "Главное сегодня",
    your_horoscope: "Гороскоп",
    your_day_horoscope: "Гороскоп",
    your_day_today: "{name}, главное на {date}",
    my_day: "Мой день",
    about_astrogotchi: "Магазин",

    time_limit: "Время для оплаты",
    payment_failure: "Что-то пошло не так 😱",
    payment_failure_text: "Ваш платеж не прошел. Проверьте данные для оплаты или баланс карты.",
    payment_failure_button: "Вернуться и попробовать еще раз",
    payment_checking: "Проверяем платеж",
    payment_success: "Спасибо",
    payment_success_text: "Вы сделали экологичный заказ с {partnerName} 😍",
    payment_success_button: "Посмотреть мой вклад в экологию",

    dateTimeFormat: "DD.MM.YYYY HH:mm",
    dateFormat: "DD.MM.YYYY",
    dateFormatShort: "DD.MM.YY",

    share: "Поделиться",
    product_url_copied: "Ссылка скопирована",
    price_from: "От",

    soft_title: "Мягкий",
    soft_description: (
      <>
        Тут текст с <b>HTML</b> для описания мягких игрушек
      </>
    ),
    digital_title: "Цифровой",
    digital_description: (
      <>
        Тут текст с <b>HTML</b> для описания цифровых игрушек
      </>
    ),

    logout: "Выйти из аккаунта",

    send: "Отправить",
    save: "Cохранить",
    edit: "Изменить",
    free: "Бесплатно",
    refresh: "Обновить",
    reset: "Сбросить",
    apply: "Применить",
    total: "Итого",
    loading: "Идет загрузка...",
    more: "eщё",
    cancel: "Отмена",
    close: "Закрыть",

    loading_hello: "Привет, {name}",

    about_header: "О тебе",
    about_your_sign: "Твой знак",
    about_you: "ты",
    About_just_like: "Как и",
    about_just_like: "как и",
    About_you: "Ты",
    about_mean: "а это значит...",

    love_header: "Любовь",
    love_description: "Любовная совместимость",

    preparing_calculation: "Анализирую планеты...",
    loading_calculation: "Готовлю твой прогноз на сегодня...",

    and: "и",
    date: "Дата",
    time: "Время",
    important: "Важно",

    show_details: "Узнать больше",

    item_code: "Код регистрации",
    item_stop: "Упс-с-с...",
    your_preferences: "Что для тебя важно?",
    your_name: "Твое имя",
    your_gender: "Пол",
    name: "Имя",
    surname: "Фамилия",
    birthDate: "Дата рождения",
    gender: "Пол",
    gender_male: "Мужчина",
    gender_male_icon: "👨",
    gender_female: "Женщина",
    gender_female_icon: "👩‍🦰",

    wrong_birthdate: "Некорректная дата рождения",
    month: "Месяц",
    month_1: "Январь",
    month_2: "Февраль",
    month_3: "Март",
    month_4: "Апрель",
    month_5: "Май",
    month_6: "Июнь",
    month_7: "Июль",
    month_8: "Август",
    month_9: "Сентябрь",
    month_10: "Октябрь",
    month_11: "Ноябрь",
    month_12: "Декабрь",

    month_block_description: "Гороскоп на месяц",
    soon: "скоро",
    digital: "digital",

    zodiac: "Знак зодиака",
    zodiac_aries: "Овен",
    zodiac_taurus: "Телец",
    zodiac_gemini: "Близнецы",
    zodiac_cancer: "Рак",
    zodiac_leo: "Лев",
    zodiac_virgo: "Дева",
    zodiac_libra: "Весы",
    zodiac_scorpio: "Скорпион",
    zodiac_sagittarius: "Стрелец",
    zodiac_capricorn: "Козерог",
    zodiac_aquarius: "Водолей",
    zodiac_pisces: "Рыбы",

    zodiac_aries_icon: "♈️",
    zodiac_taurus_icon: "♉️",
    zodiac_gemini_icon: "♊️",
    zodiac_cancer_icon: "♋️",
    zodiac_leo_icon: "♌️",
    zodiac_virgo_icon: "♍️",
    zodiac_libra_icon: "♎️",
    zodiac_scorpio_icon: "♏️",
    zodiac_sagittarius_icon: "♐️",
    zodiac_capricorn_icon: "♑️",
    zodiac_aquarius_icon: "♒️",
    zodiac_pisces_icon: "♓️",

    zodiac_aries_code: "&#9800;",
    zodiac_taurus_code: "&#9801;",
    zodiac_gemini_code: "&#9802;",
    zodiac_cancer_code: "&#9803;",
    zodiac_leo_code: "&#9804;",
    zodiac_virgo_code: "&#9805;",
    zodiac_libra_code: "&#9806;",
    zodiac_scorpio_code: "&#9807;",
    zodiac_sagittarius_code: "&#9808;",
    zodiac_capricorn_code: "&#9809;",
    zodiac_aquarius_code: "&#9810;",
    zodiac_pisces_code: "&#9811;",

    friends: "Совместимость",
    friends_today: "Совместимость сегодня",
    friends_common: "Совместимость общая",
    friend_add: "Добавить",
    friend_name: "Имя",
    friend_delete: "Удалить человека",
    friend_info_title: "Совместимость {name1} {sign1} и {name2} {sign2}",
    friends_common_info: "По жизни",
    friends_empty_text: "Добавь человека, и узнай о вашей совместимости сегодня",

    complete_button: "Ок, все понятно",
    next_day: "До нового прогноза",

    coming_soon: "Скоро",

    set_score: "Оцени прогноз",
    users_scores: "Оценка точности прогнозов",

    cookie_button: "Хорошо",
    cookie_notice: "Наш сайт использует куки для улучшения пользовательского сервиса",

    new_astrogotchi: "Регистрация нового Астрогочи",

    under_construction: "В разработке",
    under_construction_winter: "Этот контент появится в 2023 году",

    feedback: "Отзывы",
    feedback_title: "Отзывы",
    feedback_all: "Все отзывы",

    WRONG_REGISTRATION_CODE: "Неверный код",
    CODE_ALREADY_ACTIVATED: "Код уже активирован",
    CATEGORY_NOT_FOUND: "Астрогочи не найден",
    WRONG_BIRTHDATE: "Некорректная дата рождения",
  },
};
