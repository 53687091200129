import _ from "lodash";
import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { withTranslate } from "react-redux-multilingual";
import { Helmet } from "react-helmet";
import { useRouteMatch, Switch, Route } from "react-router-dom";
import CheckAssistantBlock from "./checkAssistant";
import DataBlock from "./data";
import { scrollTop } from "components/standart";
// import AssistantBlock from "components/standart/assistantBlock";

import Settings from "./settings";
import Auth from "views/auth";

import { setNavi } from "actions";

const Account = (props) => {
  const { url, path } = useRouteMatch();
  const { translate, sessionStore } = props;
  const { meta = {} } = props.env.www;

  useEffect(() => {
    scrollTop();
    props.setNavi(url);
  }, []);

  const {
    title = translate("title_index"),
    description = translate("title_description"),
    keywords = "",
  } = meta.account || {};

  return sessionStore.account ? (
    <Switch>
      <Route exact path={path}>
        <Helmet>
          <title>{title}</title>
        </Helmet>

        <CheckAssistantBlock />
        <DataBlock />

        <section className="bg-grey">
          <div className="container py-5">
            <div className="large-header text-center mb-3">{translate("settings")}</div>
            <Settings />
          </div>
        </section>
      </Route>
    </Switch>
  ) : (
    <section className="pt-2">
      <div className="container">
        <div className="row">
          <div className="col-lg-3 col-sm-hidden" />
          <div className="col">
            <div className="py-3" />
            <div className="py-3" />
            <div className="text-center">
              <div className="large-header">{translate("auth_email")}</div>
              <Auth />
            </div>
            <div className="py-4" />
          </div>
          <div className="col-lg-3 col-sm-hidden" />
        </div>
      </div>
    </section>
  );
};

const mapStateToProps = (state) => ({
  sessionStore: state.customer.session,
  systemEnv: state.customer.env,
  env: state.frontend.env,
  // assistant: state.customer.assistant,
});

export default withTranslate(
  connect(mapStateToProps, {
    setNavi,
  })(Account),
);
