import _ from "lodash";
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import moment from "moment";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { backgroundCover, ShowPager } from "components/standart";
import SwiperCore, { Pagination } from "swiper";

import { Waypoint } from "react-waypoint";
import { AvgScoreStars, scrollTop, useWindowSize } from "components/standart";

import { useTranslate } from "react-redux-multilingual";
import { fileUrl, frontendApi } from "reducers/api";
import LocalesValues, { getLocaledValue } from "components/standart/LocalesValues";
import Col3Container from "components/standart/col3Container";
import { metrikaTargetClick } from "services";

SwiperCore.use([Pagination]);

const { api } = frontendApi.actions;

const getFeedback = api.get("/feedback");
const getIndexFeedback = api.get("/feedback/indexPage");
const getFeedbackStats = api.get("/feedback/stats");

const limit = 6;

export const FastFeedback = (props) => {
  const dispatch = useDispatch();
  const translate = useTranslate();
  const [swiper, setSwiper] = useState(null);
  const [feedbackContent, setFeedbackContent] = useState({});
  const [feedbackStats, setFeedbackStats] = useState([]);

  const limit = 12;
  useEffect(() => {
    dispatch(
      getIndexFeedback(
        { query: { limit } },
        {
          onSuccess(body) {
            setFeedbackContent(body);
          },
        },
      ),
    );
    /*
    dispatch(
      getFeedbackStats(
        {},
        {
          onSuccess(body) {
            setFeedbackStats(body);
          },
        },
      ),
    );
    */
  }, []);

  const [currentIndex, setCurrentIndex] = useState(0);
  const slideChangeHandler = (event) => {
    setCurrentIndex(event.realIndex);
    return true;
  };

  return feedbackContent.total > 0 ? (
    <section className="bg-grey pt-5">
      <div className="container">
        <div className="mb-4" />

        <Col3Container>
          <div className="text-center">
            {/* раскомментировать, если надо показывать статсы по отзывам
            <div className="row">
              {_.map(feedbackStats, (stats, index) => {
                return (
                  <div key={index} className="col-12 col-md-6 col-lg-4 mx-auto">
                    {stats.marketplace?.fileId ? (
                      <img
                        src={fileUrl(stats.marketplace.fileId)}
                        width="50"
                        height="50"
                        className="rounded-circle -border -border-grey"
                        alt={getLocaledValue(stats.marketplace.localesName)}
                      />
                    ) : null}
                    {AvgScoreStars(stats.avgValue)}
                  </div>
                );
              })}
            </div>
             */}

            {/* */}
            <div className="fast-feedback" id="fast-feedback">
              <Swiper
                width={null}
                spaceBetween={10}
                slidesPerView={"auto"}
                pagination={false}
                onSwiper={setSwiper}
                onSlideChange={slideChangeHandler}
              >
                {_.map(feedbackContent.data, (feedback, index) => {
                  return (
                    <SwiperSlide key={index}>
                      <FeedbackElem {...feedback} isTruncated={true} />
                    </SwiperSlide>
                  );
                })}
              </Swiper>

              <div className="py-2 text-center">
                {swiper ? (
                  <ShowPager total={feedbackContent.total} current={currentIndex} swiper={swiper} />
                ) : null}
              </div>
            </div>
          </div>

          <div className="mb-4" />

          <div className="text-center">
            <Link
              to={"/feedback"}
              className="btn btn-outline"
              data-id="feedback"
              onClick={metrikaTargetClick}
            >
              {translate("feedback_all")}
            </Link>
          </div>
        </Col3Container>
      </div>
      <div className="mb-5" />
    </section>
  ) : null;
};

const Stars = (props) => {
  const { className, feedback } = props;
  const values = [1, 2, 3, 4, 5];
  return (
    <span style={{ color: "#f0ca0e" }} className={className}>
      {_.map(values, (value, index) => {
        return <i key={index} className={`${value <= feedback.value ? "fas" : "fal"} fa-star`} />;
      })}
    </span>
  );
};

const FeedbackElem = (feedback) => {
  const translate = useTranslate();
  const format = translate("dateFormat");
  const { isTruncated } = feedback;
  // const serviceImg = `/cloclo/services/${service}.png`;
  return (
    <div>
      <div className="mx-auto mb-4">
        <div className="d-flex justify-content-center">
          <div className="px-1">
            {feedback.marketplace?.fileId ? (
              <img
                src={fileUrl(feedback.marketplace.fileId)}
                width="50"
                height="50"
                className="rounded-circle m-0 p-0 -border -border-grey"
                alt={getLocaledValue(feedback.marketplace.localesName)}
              />
            ) : null}
          </div>
          <div className="text-left px-1">
            <b>
              <LocalesValues values={feedback.localesName} />
            </b>
            <br />
            <span>
              <Stars {...{ feedback }} className="mr-2" />
              {/* <span>{moment(feedback.realDate || feedback.createdAt).format(format)}</span> */}
            </span>
          </div>
        </div>
      </div>

      <div className="clearfix" />
      <div className={`feedback-body text-center content-html mb-4`}>
        <LocalesValues values={feedback.localesText} isHtml={true} />
        {/* {nl2br(feedback.message)} */}
      </div>
      <div className="clearfix" />
      <div className="row">
        <div className="col" />
        {_.map(feedback.filesId, (fileId, index) => {
          return (
            <div className="col-12 col-md-6 mb-2" key={index}>
              <div
                key={index}
                className="block-squared"
                style={backgroundCover(fileUrl(fileId, "stream"))}
              />
            </div>
          );
        })}
        <div className="col" />
      </div>
      <div className="clearfix" />
    </div>
  );
};

export const Feedback = (props) => {
  const dispatch = useDispatch();
  const translate = useTranslate();
  const [feedbackData, setFeedbackData] = useState({ total: 0, data: [] });
  const [hasMoreItems, setHasItems] = useState(false);
  const [loading, setLoading] = useState(false);
  const loadFeedback = (offset = 0) => {
    dispatch(
      getFeedback(
        { query: { offset, limit: 20 } },
        {
          onSuccess(body) {
            setLoading(false);
            const { total } = body;
            setFeedbackData({
              total,
              data: _.concat(feedbackData.data, body.data),
            });
          },
          onRequest() {
            setLoading(true);
          },
        },
      ),
    );
  };

  // на каждом изменении проверим, остались ли еще незагруженные
  useEffect(() => {
    setHasItems(feedbackData.total > feedbackData.data.length);
  }, [feedbackData]);

  useEffect(() => {
    scrollTop();
    loadFeedback(0);
  }, []);

  return (
    <>
      <Helmet>
        <title>{translate("feedback_title")}</title>
        <meta name="description" content={translate("feedback_description")} />
        <meta property="og:description" content={translate("feedback_description")} />
      </Helmet>

      <section>
        <div className="container">
          <Col3Container>
            {/* 
            <div className="mb-3">
              <b>
                {translate("feedback")}
              </b>
            </div>
              */}
            {_.map(feedbackData.data, (feedback, index) => (
              <div className="mb-5" key={index}>
                <FeedbackElem {...feedback} />
                <div className="pb-5" />
              </div>
            ))}
            {loading ? (
              <div className="text-center">
                <i className="fa fa-spinner-third fa-spin text-secondary text-sm m-5" />
              </div>
            ) : null}
            <Waypoint
              bottomOffset={"-100px"}
              onEnter={() => {
                hasMoreItems && loadFeedback(feedbackData.data.length);
              }}
            />
          </Col3Container>
        </div>
      </section>
    </>
  );
};

export default Feedback;
